import type { GraphQLClient, RequestOptions } from 'graphql-request';
import { GraphQLError, print } from 'graphql'
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BooleanType: { input: boolean; output: boolean; }
  CustomData: { input: Record<string, unknown>; output: Record<string, unknown>; }
  DateTime: { input: string; output: string; }
  FloatType: { input: number; output: number; }
  IntType: { input: number; output: number; }
  ItemId: { input: string; output: string; }
  JsonField: { input: unknown; output: unknown; }
  MetaTagAttributes: { input: Record<string, string>; output: Record<string, string>; }
  UploadId: { input: string; output: string; }
};

/** Block of type 🪧 All Brands Section (all_brands_section) */
export type AllBrandsSectionRecord = RecordInterface & {
  __typename: 'AllBrandsSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  items: Array<BrandRecord>;
  sectionTitle?: Maybe<Scalars['String']['output']>;
};


/** Block of type 🪧 All Brands Section (all_brands_section) */
export type AllBrandsSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type 🎠 Banner Carousel Section (banner_carousel_section) */
export type BannerCarouselSectionRecord = RecordInterface & {
  __typename: 'BannerCarouselSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  banners: Array<ImageFileField>;
  id: Scalars['ItemId']['output'];
  sectionTitle?: Maybe<Scalars['String']['output']>;
};


/** Block of type 🎠 Banner Carousel Section (banner_carousel_section) */
export type BannerCarouselSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type 🚩 Banner Section (banner_section) */
export type BannerSectionRecord = RecordInterface & {
  __typename: 'BannerSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  image: ImageFileField;
  optText?: Maybe<Scalars['String']['output']>;
  sectionTitle?: Maybe<Scalars['String']['output']>;
};


/** Block of type 🚩 Banner Section (banner_section) */
export type BannerSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Block of type 🚩 Banner Section (banner_section) */
export type BannerSectionRecordOptTextArgs = {
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Specifies how to filter Boolean fields */
export type BooleanFilter = {
  /** Search for records with an exact match */
  eq?: InputMaybe<Scalars['BooleanType']['input']>;
};

export type BrandModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<BrandModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<BrandModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _locales?: InputMaybe<LocalesFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  id?: InputMaybe<ItemIdFilter>;
  logo?: InputMaybe<FileFilter>;
  name?: InputMaybe<StringFilter>;
  optCode?: InputMaybe<StringFilter>;
  seoMeta?: InputMaybe<SeoFilter>;
  slug?: InputMaybe<SlugFilter>;
};

export enum BrandModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OptCodeAsc = 'optCode_ASC',
  OptCodeDesc = 'optCode_DESC'
}

/** Record of type Ⓜ️ Brand (brand) */
export type BrandRecord = RecordInterface & {
  __typename: 'BrandRecord';
  _allNameLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allSeoMetaLocales?: Maybe<Array<SeoFieldMultiLocaleField>>;
  _allSlugLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  logo: ImageFileField;
  name: Scalars['String']['output'];
  optCode?: Maybe<Scalars['String']['output']>;
  seoMeta?: Maybe<SeoField>;
  slug: Scalars['String']['output'];
};


/** Record of type Ⓜ️ Brand (brand) */
export type BrandRecord_AllNameLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type Ⓜ️ Brand (brand) */
export type BrandRecord_AllSeoMetaLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type Ⓜ️ Brand (brand) */
export type BrandRecord_AllSlugLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type Ⓜ️ Brand (brand) */
export type BrandRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type Ⓜ️ Brand (brand) */
export type BrandRecordNameArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type Ⓜ️ Brand (brand) */
export type BrandRecordSeoMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type Ⓜ️ Brand (brand) */
export type BrandRecordSlugArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type ButtonModelLinkItemField = CustomLinkRecord | PageLinkRecord;

/** Block of type 🎯 Button (button) */
export type ButtonRecord = RecordInterface & {
  __typename: 'ButtonRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  linkItem: ButtonModelLinkItemField;
  primary: Scalars['BooleanType']['output'];
};


/** Block of type 🎯 Button (button) */
export type ButtonRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type CallToActionModelActionLinkField = CustomLinkRecord | PageLinkRecord;

export type CallToActionModelContentField = {
  __typename: 'CallToActionModelContentField';
  blocks: Array<Scalars['String']['output']>;
  links: Array<Scalars['String']['output']>;
  value: Scalars['JsonField']['output'];
};

export type CallToActionModelContentFieldNonNullMultiLocaleField = {
  __typename: 'CallToActionModelContentFieldNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: CallToActionModelContentField;
};

export type CallToActionModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<CallToActionModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CallToActionModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _locales?: InputMaybe<LocalesFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  content?: InputMaybe<StructuredTextFilter>;
  id?: InputMaybe<ItemIdFilter>;
  image?: InputMaybe<FileFilter>;
  position?: InputMaybe<PositionFilter>;
};

export enum CallToActionModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC'
}

/** Record of type 👉 Call To Action (call_to_action) */
export type CallToActionRecord = RecordInterface & {
  __typename: 'CallToActionRecord';
  _allContentLocales?: Maybe<Array<CallToActionModelContentFieldNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  actionLink: CallToActionModelActionLinkField;
  content: CallToActionModelContentField;
  id: Scalars['ItemId']['output'];
  image: ImageFileField;
  position?: Maybe<Scalars['IntType']['output']>;
};


/** Record of type 👉 Call To Action (call_to_action) */
export type CallToActionRecord_AllContentLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 👉 Call To Action (call_to_action) */
export type CallToActionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 👉 Call To Action (call_to_action) */
export type CallToActionRecordContentArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type 👉 Call To Action Section (call_to_action_section) */
export type CallToActionSectionRecord = RecordInterface & {
  __typename: 'CallToActionSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  callToAction: CallToActionRecord;
  id: Scalars['ItemId']['output'];
  sectionTitle?: Maybe<Scalars['String']['output']>;
};


/** Block of type 👉 Call To Action Section (call_to_action_section) */
export type CallToActionSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type CategoryPageModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<CategoryPageModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CategoryPageModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _locales?: InputMaybe<LocalesFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  code?: InputMaybe<StringFilter>;
  id?: InputMaybe<ItemIdFilter>;
  seoMeta?: InputMaybe<SeoFilter>;
  slug?: InputMaybe<SlugFilter>;
  title?: InputMaybe<StringFilter>;
};

export enum CategoryPageModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type CategoryPageModelSectionsField = AllBrandsSectionRecord | BannerSectionRecord | ContentSectionRecord | FaqSectionRecord | TeaserSectionRecord | UspListSectionRecord;

export type CategoryPageModelSectionsFieldListListNonNullMultiLocaleField = {
  __typename: 'CategoryPageModelSectionsFieldListListNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: Array<CategoryPageModelSectionsField>;
};

/** Record of type 🏡 Category Page (category_page) */
export type CategoryPageRecord = RecordInterface & {
  __typename: 'CategoryPageRecord';
  _allSectionsLocales?: Maybe<Array<CategoryPageModelSectionsFieldListListNonNullMultiLocaleField>>;
  _allSeoMetaLocales?: Maybe<Array<SeoFieldMultiLocaleField>>;
  _allSlugLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  code: Scalars['String']['output'];
  id: Scalars['ItemId']['output'];
  sections: Array<CategoryPageModelSectionsField>;
  seoMeta?: Maybe<SeoField>;
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
};


/** Record of type 🏡 Category Page (category_page) */
export type CategoryPageRecord_AllSectionsLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏡 Category Page (category_page) */
export type CategoryPageRecord_AllSeoMetaLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏡 Category Page (category_page) */
export type CategoryPageRecord_AllSlugLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏡 Category Page (category_page) */
export type CategoryPageRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏡 Category Page (category_page) */
export type CategoryPageRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏡 Category Page (category_page) */
export type CategoryPageRecordSectionsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏡 Category Page (category_page) */
export type CategoryPageRecordSeoMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏡 Category Page (category_page) */
export type CategoryPageRecordSlugArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏡 Category Page (category_page) */
export type CategoryPageRecordTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type 🧾 Centered Text Section (centered_text_section) */
export type CenteredTextSectionRecord = RecordInterface & {
  __typename: 'CenteredTextSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  sectionTitle?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
};


/** Block of type 🧾 Centered Text Section (centered_text_section) */
export type CenteredTextSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Block of type 🧾 Centered Text Section (centered_text_section) */
export type CenteredTextSectionRecordTextArgs = {
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Block of type 🧾 Centered Text Section (centered_text_section) */
export type CenteredTextSectionRecordTitleArgs = {
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CollectionMetadata = {
  __typename: 'CollectionMetadata';
  count: Scalars['IntType']['output'];
};

export enum ColorBucketType {
  Black = 'black',
  Blue = 'blue',
  Brown = 'brown',
  Cyan = 'cyan',
  Green = 'green',
  Grey = 'grey',
  Orange = 'orange',
  Pink = 'pink',
  Purple = 'purple',
  Red = 'red',
  White = 'white',
  Yellow = 'yellow'
}

export type ColorField = {
  __typename: 'ColorField';
  alpha: Scalars['IntType']['output'];
  blue: Scalars['IntType']['output'];
  cssRgb: Scalars['String']['output'];
  green: Scalars['IntType']['output'];
  hex: Scalars['String']['output'];
  red: Scalars['IntType']['output'];
};

/** Record of type 📧 Contact Form (contact_form) */
export type ContactFormRecord = RecordInterface & {
  __typename: 'ContactFormRecord';
  _allActionSubmitLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allConfirmationLocales?: Maybe<Array<NotificationRecordNonNullMultiLocaleField>>;
  _allExceptionLocales?: Maybe<Array<NotificationRecordNonNullMultiLocaleField>>;
  _allHelpTextMessageLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allLabelCompanyNameLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allLabelCompanyNumberLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allLabelDealerNameLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allLabelEMailAddressLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allLabelFirstNameLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allLabelLastNameLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allLabelMessageLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allLabelPhoneCountryCodeLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allLabelPhoneNumberLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allLabelSubjectLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allPlaceholderSubjectLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allSubjectsLocales?: Maybe<Array<TextItemRecordListListNonNullMultiLocaleField>>;
  _allTitleDealerLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allTitleInfoLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allTitleReasonLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  actionSubmit: Scalars['String']['output'];
  confirmation: NotificationRecord;
  countries: Array<CountryRecord>;
  dealers: Array<DealerRecord>;
  exception: NotificationRecord;
  helpTextMessage: Scalars['String']['output'];
  id: Scalars['ItemId']['output'];
  labelCompanyName: Scalars['String']['output'];
  labelCompanyNumber: Scalars['String']['output'];
  labelDealerName: Scalars['String']['output'];
  labelEMailAddress: Scalars['String']['output'];
  labelFirstName: Scalars['String']['output'];
  labelLastName: Scalars['String']['output'];
  labelMessage: Scalars['String']['output'];
  labelPhoneCountryCode: Scalars['String']['output'];
  labelPhoneNumber: Scalars['String']['output'];
  labelSubject: Scalars['String']['output'];
  placeholderSubject: Scalars['String']['output'];
  subjects: Array<TextItemRecord>;
  titleDealer: Scalars['String']['output'];
  titleInfo: Scalars['String']['output'];
  titleReason: Scalars['String']['output'];
};


/** Record of type 📧 Contact Form (contact_form) */
export type ContactFormRecord_AllActionSubmitLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📧 Contact Form (contact_form) */
export type ContactFormRecord_AllConfirmationLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📧 Contact Form (contact_form) */
export type ContactFormRecord_AllExceptionLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📧 Contact Form (contact_form) */
export type ContactFormRecord_AllHelpTextMessageLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📧 Contact Form (contact_form) */
export type ContactFormRecord_AllLabelCompanyNameLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📧 Contact Form (contact_form) */
export type ContactFormRecord_AllLabelCompanyNumberLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📧 Contact Form (contact_form) */
export type ContactFormRecord_AllLabelDealerNameLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📧 Contact Form (contact_form) */
export type ContactFormRecord_AllLabelEMailAddressLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📧 Contact Form (contact_form) */
export type ContactFormRecord_AllLabelFirstNameLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📧 Contact Form (contact_form) */
export type ContactFormRecord_AllLabelLastNameLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📧 Contact Form (contact_form) */
export type ContactFormRecord_AllLabelMessageLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📧 Contact Form (contact_form) */
export type ContactFormRecord_AllLabelPhoneCountryCodeLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📧 Contact Form (contact_form) */
export type ContactFormRecord_AllLabelPhoneNumberLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📧 Contact Form (contact_form) */
export type ContactFormRecord_AllLabelSubjectLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📧 Contact Form (contact_form) */
export type ContactFormRecord_AllPlaceholderSubjectLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📧 Contact Form (contact_form) */
export type ContactFormRecord_AllSubjectsLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📧 Contact Form (contact_form) */
export type ContactFormRecord_AllTitleDealerLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📧 Contact Form (contact_form) */
export type ContactFormRecord_AllTitleInfoLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📧 Contact Form (contact_form) */
export type ContactFormRecord_AllTitleReasonLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📧 Contact Form (contact_form) */
export type ContactFormRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📧 Contact Form (contact_form) */
export type ContactFormRecordActionSubmitArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📧 Contact Form (contact_form) */
export type ContactFormRecordConfirmationArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📧 Contact Form (contact_form) */
export type ContactFormRecordExceptionArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📧 Contact Form (contact_form) */
export type ContactFormRecordHelpTextMessageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📧 Contact Form (contact_form) */
export type ContactFormRecordLabelCompanyNameArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📧 Contact Form (contact_form) */
export type ContactFormRecordLabelCompanyNumberArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📧 Contact Form (contact_form) */
export type ContactFormRecordLabelDealerNameArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📧 Contact Form (contact_form) */
export type ContactFormRecordLabelEMailAddressArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📧 Contact Form (contact_form) */
export type ContactFormRecordLabelFirstNameArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📧 Contact Form (contact_form) */
export type ContactFormRecordLabelLastNameArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📧 Contact Form (contact_form) */
export type ContactFormRecordLabelMessageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📧 Contact Form (contact_form) */
export type ContactFormRecordLabelPhoneCountryCodeArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📧 Contact Form (contact_form) */
export type ContactFormRecordLabelPhoneNumberArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📧 Contact Form (contact_form) */
export type ContactFormRecordLabelSubjectArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📧 Contact Form (contact_form) */
export type ContactFormRecordPlaceholderSubjectArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📧 Contact Form (contact_form) */
export type ContactFormRecordSubjectsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📧 Contact Form (contact_form) */
export type ContactFormRecordTitleDealerArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📧 Contact Form (contact_form) */
export type ContactFormRecordTitleInfoArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📧 Contact Form (contact_form) */
export type ContactFormRecordTitleReasonArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type 📧 Contact Teasers Section (contact_teasers_section) */
export type ContactTeasersSectionRecord = RecordInterface & {
  __typename: 'ContactTeasersSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  sectionTitle?: Maybe<Scalars['String']['output']>;
  teasers: Array<TeaserRecord>;
};


/** Block of type 📧 Contact Teasers Section (contact_teasers_section) */
export type ContactTeasersSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type ContentSectionModelBodyBlocksField = ButtonRecord | ImageBlockRecord | SplitItemRecord;

export type ContentSectionModelBodyField = {
  __typename: 'ContentSectionModelBodyField';
  blocks: Array<ContentSectionModelBodyBlocksField>;
  links: Array<Scalars['String']['output']>;
  value: Scalars['JsonField']['output'];
};

/** Block of type ✍️ Content Section (content_section) */
export type ContentSectionRecord = RecordInterface & {
  __typename: 'ContentSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  body: ContentSectionModelBodyField;
  id: Scalars['ItemId']['output'];
};


/** Block of type ✍️ Content Section (content_section) */
export type ContentSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type CountryModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<CountryModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CountryModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _locales?: InputMaybe<LocalesFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  id?: InputMaybe<ItemIdFilter>;
  isoCode?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  phoneNumberCode?: InputMaybe<StringFilter>;
  position?: InputMaybe<PositionFilter>;
};

export enum CountryModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IsoCodeAsc = 'isoCode_ASC',
  IsoCodeDesc = 'isoCode_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PhoneNumberCodeAsc = 'phoneNumberCode_ASC',
  PhoneNumberCodeDesc = 'phoneNumberCode_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC'
}

/** Record of type 🗾 Country (country) */
export type CountryRecord = RecordInterface & {
  __typename: 'CountryRecord';
  _allNameLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  isoCode: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phoneNumberCode: Scalars['String']['output'];
  position?: Maybe<Scalars['IntType']['output']>;
};


/** Record of type 🗾 Country (country) */
export type CountryRecord_AllNameLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🗾 Country (country) */
export type CountryRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🗾 Country (country) */
export type CountryRecordNameArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter by creation datetime */
export type CreatedAtFilter = {
  /** Filter records with a value that's within the specified minute range. Seconds and milliseconds are truncated from the argument. */
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records with a value that's strictly greater than the one specified. Seconds and milliseconds are truncated from the argument. */
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's greater than or equal to than the one specified. Seconds and milliseconds are truncated from the argument. */
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's less than the one specified. Seconds and milliseconds are truncated from the argument. */
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's less or equal than the one specified. Seconds and milliseconds are truncated from the argument. */
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's outside the specified minute range. Seconds and milliseconds are truncated from the argument. */
  neq?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Block of type 🤝 Custom Link (custom_link) */
export type CustomLinkRecord = RecordInterface & {
  __typename: 'CustomLinkRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  optLinkUrl?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
};


/** Block of type 🤝 Custom Link (custom_link) */
export type CustomLinkRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type DealerModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<DealerModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<DealerModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _locales?: InputMaybe<LocalesFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  address?: InputMaybe<StringFilter>;
  eMailAddress?: InputMaybe<StringFilter>;
  id?: InputMaybe<ItemIdFilter>;
  name?: InputMaybe<StringFilter>;
  phoneNumber?: InputMaybe<StringFilter>;
  position?: InputMaybe<PositionFilter>;
};

export enum DealerModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  AddressAsc = 'address_ASC',
  AddressDesc = 'address_DESC',
  EMailAddressAsc = 'eMailAddress_ASC',
  EMailAddressDesc = 'eMailAddress_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PhoneNumberAsc = 'phoneNumber_ASC',
  PhoneNumberDesc = 'phoneNumber_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC'
}

/** Record of type 👨‍💼 Dealer (dealer) */
export type DealerRecord = RecordInterface & {
  __typename: 'DealerRecord';
  _allNameLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  address: Scalars['String']['output'];
  eMailAddress: Scalars['String']['output'];
  id: Scalars['ItemId']['output'];
  name: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  position?: Maybe<Scalars['IntType']['output']>;
};


/** Record of type 👨‍💼 Dealer (dealer) */
export type DealerRecord_AllNameLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 👨‍💼 Dealer (dealer) */
export type DealerRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 👨‍💼 Dealer (dealer) */
export type DealerRecordNameArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type 👨‍💼 Dealers Section (dealers_section) */
export type DealersSectionRecord = RecordInterface & {
  __typename: 'DealersSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  dealers: Array<DealerRecord>;
  id: Scalars['ItemId']['output'];
  mapImage: ImageFileField;
  sectionTitle?: Maybe<Scalars['String']['output']>;
};


/** Block of type 👨‍💼 Dealers Section (dealers_section) */
export type DealersSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type Error404ModelBodyField = {
  __typename: 'Error404ModelBodyField';
  blocks: Array<ImageBlockRecord>;
  links: Array<Scalars['String']['output']>;
  value: Scalars['JsonField']['output'];
};

export type Error404ModelBodyFieldNonNullMultiLocaleField = {
  __typename: 'Error404ModelBodyFieldNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: Error404ModelBodyField;
};

/** Record of type ⚠️ Error404 (error404) */
export type Error404Record = RecordInterface & {
  __typename: 'Error404Record';
  _allBodyLocales?: Maybe<Array<Error404ModelBodyFieldNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  body: Error404ModelBodyField;
  id: Scalars['ItemId']['output'];
};


/** Record of type ⚠️ Error404 (error404) */
export type Error404Record_AllBodyLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type ⚠️ Error404 (error404) */
export type Error404Record_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type ⚠️ Error404 (error404) */
export type Error404RecordBodyArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type FaqModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<FaqModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FaqModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _locales?: InputMaybe<LocalesFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  answer?: InputMaybe<TextFilter>;
  id?: InputMaybe<ItemIdFilter>;
  position?: InputMaybe<PositionFilter>;
  question?: InputMaybe<StringFilter>;
};

export enum FaqModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  QuestionAsc = 'question_ASC',
  QuestionDesc = 'question_DESC'
}

/** Record of type ❓ FAQ (faq) */
export type FaqRecord = RecordInterface & {
  __typename: 'FaqRecord';
  _allAnswerLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allQuestionLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  answer: Scalars['String']['output'];
  id: Scalars['ItemId']['output'];
  position?: Maybe<Scalars['IntType']['output']>;
  question: Scalars['String']['output'];
};


/** Record of type ❓ FAQ (faq) */
export type FaqRecord_AllAnswerLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type ❓ FAQ (faq) */
export type FaqRecord_AllQuestionLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type ❓ FAQ (faq) */
export type FaqRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type ❓ FAQ (faq) */
export type FaqRecordAnswerArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type ❓ FAQ (faq) */
export type FaqRecordQuestionArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type ❓ FAQ Section (faq_section) */
export type FaqSectionRecord = RecordInterface & {
  __typename: 'FaqSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  items: Array<FaqRecord>;
  sectionTitle?: Maybe<Scalars['String']['output']>;
};


/** Block of type ❓ FAQ Section (faq_section) */
export type FaqSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export enum FaviconType {
  AppleTouchIcon = 'appleTouchIcon',
  Icon = 'icon',
  MsApplication = 'msApplication'
}

export type FileField = FileFieldInterface & {
  __typename: 'FileField';
  _createdAt: Scalars['DateTime']['output'];
  /** The DatoCMS URL where you can edit this entity. To use this field, you need to set a X-Base-Editing-Url header in the request */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  alt?: Maybe<Scalars['String']['output']>;
  author?: Maybe<Scalars['String']['output']>;
  basename: Scalars['String']['output'];
  blurUpThumb?: Maybe<Scalars['String']['output']>;
  blurhash?: Maybe<Scalars['String']['output']>;
  colors: Array<ColorField>;
  copyright?: Maybe<Scalars['String']['output']>;
  customData: Scalars['CustomData']['output'];
  exifInfo: Scalars['CustomData']['output'];
  filename: Scalars['String']['output'];
  focalPoint?: Maybe<FocalPoint>;
  format: Scalars['String']['output'];
  height?: Maybe<Scalars['IntType']['output']>;
  id: Scalars['UploadId']['output'];
  md5: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  responsiveImage?: Maybe<ResponsiveImage>;
  size: Scalars['IntType']['output'];
  smartTags: Array<Scalars['String']['output']>;
  tags: Array<Scalars['String']['output']>;
  thumbhash?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
  video?: Maybe<UploadVideoField>;
  width?: Maybe<Scalars['IntType']['output']>;
};


export type FileFieldAltArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


export type FileFieldBlurUpThumbArgs = {
  imgixParams?: InputMaybe<ImgixParams>;
  punch?: Scalars['Float']['input'];
  quality?: Scalars['Int']['input'];
  size?: Scalars['Int']['input'];
};


export type FileFieldCustomDataArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


export type FileFieldFocalPointArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


export type FileFieldResponsiveImageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  imgixParams?: InputMaybe<ImgixParams>;
  locale?: InputMaybe<SiteLocale>;
  sizes?: InputMaybe<Scalars['String']['input']>;
};


export type FileFieldTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


export type FileFieldUrlArgs = {
  imgixParams?: InputMaybe<ImgixParams>;
};

export type FileFieldInterface = {
  _createdAt: Scalars['DateTime']['output'];
  /** The DatoCMS URL where you can edit this entity. To use this field, you need to set a X-Base-Editing-Url header in the request */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  alt?: Maybe<Scalars['String']['output']>;
  author?: Maybe<Scalars['String']['output']>;
  basename: Scalars['String']['output'];
  blurUpThumb?: Maybe<Scalars['String']['output']>;
  blurhash?: Maybe<Scalars['String']['output']>;
  colors: Array<ColorField>;
  copyright?: Maybe<Scalars['String']['output']>;
  customData: Scalars['CustomData']['output'];
  exifInfo: Scalars['CustomData']['output'];
  filename: Scalars['String']['output'];
  focalPoint?: Maybe<FocalPoint>;
  format: Scalars['String']['output'];
  height?: Maybe<Scalars['IntType']['output']>;
  id: Scalars['UploadId']['output'];
  md5: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  responsiveImage?: Maybe<ResponsiveImage>;
  size: Scalars['IntType']['output'];
  smartTags: Array<Scalars['String']['output']>;
  tags: Array<Scalars['String']['output']>;
  thumbhash?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
  video?: Maybe<UploadVideoField>;
  width?: Maybe<Scalars['IntType']['output']>;
};


export type FileFieldInterfaceAltArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


export type FileFieldInterfaceBlurUpThumbArgs = {
  imgixParams?: InputMaybe<ImgixParams>;
  punch?: Scalars['Float']['input'];
  quality?: Scalars['Int']['input'];
  size?: Scalars['Int']['input'];
};


export type FileFieldInterfaceCustomDataArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


export type FileFieldInterfaceFocalPointArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


export type FileFieldInterfaceResponsiveImageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  imgixParams?: InputMaybe<ImgixParams>;
  locale?: InputMaybe<SiteLocale>;
  sizes?: InputMaybe<Scalars['String']['input']>;
};


export type FileFieldInterfaceTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


export type FileFieldInterfaceUrlArgs = {
  imgixParams?: InputMaybe<ImgixParams>;
};

/** Specifies how to filter Single-file/image fields */
export type FileFilter = {
  /** Search for records with an exact match. The specified value must be an Upload ID */
  eq?: InputMaybe<Scalars['UploadId']['input']>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records that have one of the specified uploads */
  in?: InputMaybe<Array<InputMaybe<Scalars['UploadId']['input']>>>;
  /** Exclude records with an exact match. The specified value must be an Upload ID */
  neq?: InputMaybe<Scalars['UploadId']['input']>;
  /** Filter records that do not have one of the specified uploads */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['UploadId']['input']>>>;
};

export type GlobalSeoField = {
  __typename: 'GlobalSeoField';
  facebookPageUrl?: Maybe<Scalars['String']['output']>;
  fallbackSeo?: Maybe<SeoField>;
  siteName?: Maybe<Scalars['String']['output']>;
  titleSuffix?: Maybe<Scalars['String']['output']>;
  twitterAccount?: Maybe<Scalars['String']['output']>;
};

/** Block of type 🤩 Icon Feature (icon_feature) */
export type IconFeatureRecord = RecordInterface & {
  __typename: 'IconFeatureRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  icon: ImageFileField;
  id: Scalars['ItemId']['output'];
  optText?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};


/** Block of type 🤩 Icon Feature (icon_feature) */
export type IconFeatureRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Block of type 🤩 Icon Feature (icon_feature) */
export type IconFeatureRecordOptTextArgs = {
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Block of type 🎞️ Icon Features Section (icon_features_section) */
export type IconFeaturesSectionRecord = RecordInterface & {
  __typename: 'IconFeaturesSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  colorMode: Scalars['BooleanType']['output'];
  features: Array<IconFeatureRecord>;
  id: Scalars['ItemId']['output'];
  sectionTitle?: Maybe<Scalars['String']['output']>;
};


/** Block of type 🎞️ Icon Features Section (icon_features_section) */
export type IconFeaturesSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type IconLinkModelLinkItemField = CustomLinkRecord | PageLinkRecord;

/** Block of type 🤝 Icon Link (icon_link) */
export type IconLinkRecord = RecordInterface & {
  __typename: 'IconLinkRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  iconName: Scalars['String']['output'];
  id: Scalars['ItemId']['output'];
  linkItem: IconLinkModelLinkItemField;
};


/** Block of type 🤝 Icon Link (icon_link) */
export type IconLinkRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type 🌅 Image Block (image_block) */
export type ImageBlockRecord = RecordInterface & {
  __typename: 'ImageBlockRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  image: ImageFileField;
};


/** Block of type 🌅 Image Block (image_block) */
export type ImageBlockRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type 🤩 Image Feature (image_feature) */
export type ImageFeatureRecord = RecordInterface & {
  __typename: 'ImageFeatureRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  image: ImageFileField;
  optText?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};


/** Block of type 🤩 Image Feature (image_feature) */
export type ImageFeatureRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Block of type 🤩 Image Feature (image_feature) */
export type ImageFeatureRecordOptTextArgs = {
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Block of type 🎞️ Image Features Section (image_features_section) */
export type ImageFeaturesSectionRecord = RecordInterface & {
  __typename: 'ImageFeaturesSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  colorMode: Scalars['BooleanType']['output'];
  features: Array<ImageFeatureRecord>;
  id: Scalars['ItemId']['output'];
  sectionTitle?: Maybe<Scalars['String']['output']>;
};


/** Block of type 🎞️ Image Features Section (image_features_section) */
export type ImageFeaturesSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type ImageFileField = FileFieldInterface & {
  __typename: 'ImageFileField';
  _createdAt: Scalars['DateTime']['output'];
  /** The DatoCMS URL where you can edit this entity. To use this field, you need to set a X-Base-Editing-Url header in the request */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  alt?: Maybe<Scalars['String']['output']>;
  author?: Maybe<Scalars['String']['output']>;
  basename: Scalars['String']['output'];
  blurUpThumb?: Maybe<Scalars['String']['output']>;
  blurhash?: Maybe<Scalars['String']['output']>;
  colors: Array<ColorField>;
  copyright?: Maybe<Scalars['String']['output']>;
  customData: Scalars['CustomData']['output'];
  exifInfo: Scalars['CustomData']['output'];
  filename: Scalars['String']['output'];
  focalPoint: FocalPoint;
  format: Scalars['String']['output'];
  height: Scalars['IntType']['output'];
  id: Scalars['UploadId']['output'];
  md5: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  responsiveImage: ResponsiveImage;
  size: Scalars['IntType']['output'];
  smartTags: Array<Scalars['String']['output']>;
  tags: Array<Scalars['String']['output']>;
  thumbhash?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
  video?: Maybe<UploadVideoField>;
  width: Scalars['IntType']['output'];
};


export type ImageFileFieldAltArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


export type ImageFileFieldBlurUpThumbArgs = {
  imgixParams?: InputMaybe<ImgixParams>;
  punch?: Scalars['Float']['input'];
  quality?: Scalars['Int']['input'];
  size?: Scalars['Int']['input'];
};


export type ImageFileFieldCustomDataArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


export type ImageFileFieldFocalPointArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


export type ImageFileFieldResponsiveImageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  imgixParams?: InputMaybe<ImgixParams>;
  locale?: InputMaybe<SiteLocale>;
  sizes?: InputMaybe<Scalars['String']['input']>;
};


export type ImageFileFieldTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


export type ImageFileFieldUrlArgs = {
  imgixParams?: InputMaybe<ImgixParams>;
};

export type ImageLinkModelLinkItemField = CustomLinkRecord | PageLinkRecord;

/** Block of type 🤝 Image Link (image_link) */
export type ImageLinkRecord = RecordInterface & {
  __typename: 'ImageLinkRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  image: ImageFileField;
  linkItem: ImageLinkModelLinkItemField;
};


/** Block of type 🤝 Image Link (image_link) */
export type ImageLinkRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type ImgixParams = {
  /**
   * Aspect Ratio
   *
   * Specifies an aspect ratio to maintain when resizing and cropping the image
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/ar)
   */
  ar?: InputMaybe<Scalars['String']['input']>;
  /**
   * Automatic
   *
   * Applies automatic enhancements to images.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/auto)
   */
  auto?: InputMaybe<Array<ImgixParamsAuto>>;
  /**
   * Background Color
   *
   * Colors the background of padded and partially-transparent images.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/bg)
   */
  bg?: InputMaybe<Scalars['String']['input']>;
  /**
   * Background Removal
   *
   * Removes background from image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/bg-remove)
   */
  bgRemove?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Background Removal Fallback
   *
   * Overrides default fallback behavior for bg-remove failures.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/bg-remove)
   */
  bgRemoveFallback?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Background Replacement
   *
   * Replaces background from image using a string based prompt.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/bg-replace)
   */
  bgReplace?: InputMaybe<Scalars['String']['input']>;
  /**
   * Background Removal Fallback
   *
   * Overrides default fallback behavior for bg-replace failures.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/bg-replace)
   */
  bgReplaceFallback?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Background Replacement Negative Prompt
   *
   * Provides a negative text suggestion for background replacement.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/bg-replace-neg-prompt)
   */
  bgReplaceNegPrompt?: InputMaybe<Scalars['String']['input']>;
  /**
   * Blend
   *
   * Specifies the location of the blend image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend)
   */
  blend?: InputMaybe<Scalars['String']['input']>;
  /**
   * Blend Align
   *
   * Changes the blend alignment relative to the parent image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-align)
   */
  blendAlign?: InputMaybe<Array<ImgixParamsBlendAlign>>;
  /**
   * Blend Alpha
   *
   * Changes the alpha of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-alpha)
   */
  blendAlpha?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Blend Color
   *
   * Specifies a color to use when applying the blend.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-color)
   */
  blendColor?: InputMaybe<Scalars['String']['input']>;
  /**
   * Blend Crop
   *
   * Specifies the type of crop for blend images.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-crop)
   */
  blendCrop?: InputMaybe<Array<ImgixParamsBlendCrop>>;
  /**
   * Blend Fit
   *
   * Specifies the fit mode for blend images.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-fit)
   */
  blendFit?: InputMaybe<ImgixParamsBlendFit>;
  /**
   * Blend Height
   *
   * Adjusts the height of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-h)
   */
  blendH?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Blend Mode
   *
   * Sets the blend mode for a blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-mode)
   */
  blendMode?: InputMaybe<ImgixParamsBlendMode>;
  /**
   * Blend Padding
   *
   * Applies padding to the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-pad)
   */
  blendPad?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Blend Size
   *
   * Adjusts the size of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-size)
   */
  blendSize?: InputMaybe<ImgixParamsBlendSize>;
  /**
   * Blend Width
   *
   * Adjusts the width of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-w)
   */
  blendW?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Blend X Position
   *
   * Adjusts the x-offset of the blend image relative to its parent.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-x)
   */
  blendX?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Blend Y Position
   *
   * Adjusts the y-offset of the blend image relative to its parent.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-y)
   */
  blendY?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Gaussian Blur
   *
   * Applies a gaussian blur to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/blur)
   */
  blur?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Border Size & Color
   *
   * Applies a border to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border)
   */
  border?: InputMaybe<Scalars['String']['input']>;
  /**
   * Border Bottom
   *
   * Sets bottom border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-bottom)
   */
  borderBottom?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Border Left
   *
   * Sets left border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-left)
   */
  borderLeft?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Outer Border Radius
   *
   * Sets the outer radius of the image's border in pixels.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-radius)
   */
  borderRadius?: InputMaybe<Scalars['String']['input']>;
  /**
   * Inner Border Radius
   *
   * Sets the inner radius of the image's border in pixels.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-radius-inner)
   */
  borderRadiusInner?: InputMaybe<Scalars['String']['input']>;
  /**
   * Border Right
   *
   * Sets right border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-right)
   */
  borderRight?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Border Top
   *
   * Sets top border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-top)
   */
  borderTop?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Brightness
   *
   * Adjusts the brightness of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/bri)
   */
  bri?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Client Hints
   *
   * Sets one or more Client-Hints headers
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/ch)
   */
  ch?: InputMaybe<Array<ImgixParamsCh>>;
  /**
   * Chroma Subsampling
   *
   * Specifies the output chroma subsampling rate.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/chromasub)
   */
  chromasub?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Color Quantization
   *
   * Limits the number of unique colors in an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/colorquant)
   */
  colorquant?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Palette Color Count
   *
   * Specifies how many colors to include in a palette-extraction response.
   *
   * Depends on: `palette`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/color-palette/colors)
   */
  colors?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Contrast
   *
   * Adjusts the contrast of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/con)
   */
  con?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Mask Corner Radius
   *
   * Specifies the radius value for a rounded corner mask.
   *
   * Depends on: `mask=corners`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/mask/corner-radius)
   */
  cornerRadius?: InputMaybe<Scalars['String']['input']>;
  /**
   * Crop Mode
   *
   * Specifies how to crop an image.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/crop)
   */
  crop?: InputMaybe<Array<ImgixParamsCrop>>;
  /**
   * Color Space
   *
   * Specifies the color space of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/cs)
   */
  cs?: InputMaybe<ImgixParamsCs>;
  /**
   * Download
   *
   * Forces a URL to use send-file in its response.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/dl)
   */
  dl?: InputMaybe<Scalars['String']['input']>;
  /**
   * Dots Per Inch
   *
   * Sets the DPI value in the EXIF header.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/dpi)
   */
  dpi?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Device Pixel Ratio
   *
   * Adjusts the device-pixel ratio of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/dpr)
   */
  dpr?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Duotone
   *
   * Applies a duotone effect to the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/duotone)
   */
  duotone?: InputMaybe<Scalars['String']['input']>;
  /**
   * Duotone Alpha
   *
   * Changes the alpha of the duotone effect atop the source image.
   *
   * Depends on: `duotone`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/duotone-alpha)
   */
  duotoneAlpha?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Exposure
   *
   * Adjusts the exposure of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/exp)
   */
  exp?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Url Expiration Timestamp
   *
   * A Unix timestamp specifying a UTC time. Requests made to this URL after that time will output a 404 status code.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/expires)
   */
  expires?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Face Index
   *
   * Selects a face to crop to.
   *
   * Depends on: `fit=facearea`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/face-detection/faceindex)
   */
  faceindex?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Face Padding
   *
   * Adjusts padding around a selected face.
   *
   * Depends on: `fit=facearea`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/face-detection/facepad)
   */
  facepad?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Json Face Data
   *
   * Specifies that face data should be included in output when combined with `fm=json`.
   *
   * Depends on: `fm=json`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/face-detection/faces)
   */
  faces?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Fill Mode
   *
   * Determines how to fill in additional space created by the fit setting
   *
   * Depends on: `fit`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill)
   */
  fill?: InputMaybe<ImgixParamsFill>;
  /**
   * Fill Color
   *
   * Sets the fill color for images with additional space created by the fit setting
   *
   * Depends on: `fill=solid`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-color)
   */
  fillColor?: InputMaybe<Scalars['String']['input']>;
  /**
   * Fill Generative Fallback
   *
   * Sets the fallback behavior for generative fill.
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gen-fallback)
   */
  fillGenFallback?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Fill Generative Negative Prompt
   *
   * Provides a negative text suggestion to the generative fill parameter. Used to reduce the probability of a subject, detail, or object appearing in generative output.
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gen-neg-prompt)
   */
  fillGenNegPrompt?: InputMaybe<Scalars['String']['input']>;
  /**
   * Fill Generative Position
   *
   * Sets the position of the Origin Image in relation to the generative fill.
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gen-pos)
   */
  fillGenPos?: InputMaybe<Array<ImgixParamsFillGenPos>>;
  /**
   * Fill Generative Prompt
   *
   * Provides a text suggestion to the generative fill parameter.
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gen-prompt)
   */
  fillGenPrompt?: InputMaybe<Scalars['String']['input']>;
  /**
   * Fill Generative Seed
   *
   * Sets the generative seed value. Used to generate similar outputs from different prompts.
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gen-seed)
   */
  fillGenSeed?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Fill Gradient Color Space
   *
   * Defines the color space as linear, sRGB, Oklab, HSL, or LCH for gradient color interpolation
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-cs)
   */
  fillGradientCs?: InputMaybe<ImgixParamsFillGradientCs>;
  /**
   * Fill Gradient Linear
   *
   * Blends a gradient between two colors, {color1} and {color2}, along a straight path
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-linear)
   */
  fillGradientLinear?: InputMaybe<Scalars['String']['input']>;
  /**
   * Fill Gradient Linear Direction
   *
   * The fill-gradient-linear-direction specifies the gradient's direction, flowing towards the bottom, top, right, or left
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-linear-direction)
   */
  fillGradientLinearDirection?: InputMaybe<Array<ImgixParamsFillGradientLinearDirection>>;
  /**
   * Fill Gradient Radial
   *
   * The fill-gradient-radial parameter creates a circular gradient transitioning from a central color (Color1) to an outer color (Color2)
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-radial)
   */
  fillGradientRadial?: InputMaybe<Scalars['String']['input']>;
  /**
   * Fill Gradient Radial Radius
   *
   * Parameter defines the radial gradient's radius as pixels or a percentage (0.0-1.0) of the image's smallest dimension
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-radial-radius)
   */
  fillGradientRadialRadius?: InputMaybe<Scalars['String']['input']>;
  /**
   * Fill Gradient Radial X
   *
   * Specifies the location of the radial gradient's center along the x-axis, using either a pixel value or a floating point percentage (ranging from 0.0 to 1.0) of the image's width
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-radial-x)
   */
  fillGradientRadialX?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Fill Gradient Radial Y
   *
   * Parameter sets the radial gradient's center on the y-axis, using pixels or a 0.0 to 1.0 percentage of the image's height
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-radial-y)
   */
  fillGradientRadialY?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Fill Gradient Type
   *
   * Specifies if a gradient is radial (circular) or linear (straight)
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-type)
   */
  fillGradientType?: InputMaybe<ImgixParamsFillGradientType>;
  /**
   * Resize Fit Mode
   *
   * Specifies how to map the source image to the output image dimensions.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/fit)
   */
  fit?: InputMaybe<ImgixParamsFit>;
  /**
   * Flip Axis
   *
   * Flips an image on a specified axis.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/rotation/flip)
   */
  flip?: InputMaybe<ImgixParamsFlip>;
  /**
   * Output Format
   *
   * Changes the format of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/fm)
   */
  fm?: InputMaybe<ImgixParamsFm>;
  /**
   * Focal Point Debug
   *
   * Displays crosshairs identifying the location of the set focal point
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-debug)
   */
  fpDebug?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Focal Point X Position
   *
   * Sets the relative horizontal value for the focal point of an image
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-x)
   */
  fpX?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Focal Point Y Position
   *
   * Sets the relative vertical value for the focal point of an image
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-y)
   */
  fpY?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Focal Point Zoom
   *
   * Sets the relative zoom value for the focal point of an image
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-z)
   */
  fpZ?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Frames Per Second
   *
   * Specifies the framerate of the generated image.
   */
  fps?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Frame Selection
   *
   * Specifies the frame of an animated image to use.
   */
  frame?: InputMaybe<Scalars['String']['input']>;
  /**
   * Gamma
   *
   * Adjusts the gamma of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/gam)
   */
  gam?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Animated Gif Quality
   *
   * Specifies the quality of the animated gif. The higher the value, the better more compression is applied.
   *
   * Depends on: `fm=gif`
   */
  gifQ?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Grid Colors
   *
   * Sets grid colors for the transparency checkerboard grid.
   *
   * Depends on: `transparency`
   */
  gridColors?: InputMaybe<Scalars['String']['input']>;
  /**
   * Grid Size
   *
   * Sets grid size for the transparency checkerboard grid.
   *
   * Depends on: `transparency`
   */
  gridSize?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Image Height
   *
   * Adjusts the height of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/h)
   */
  h?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Highlight
   *
   * Adjusts the highlights of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/high)
   */
  high?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Halftone
   *
   * Applies a half-tone effect to the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/htn)
   */
  htn?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Hue Shift
   *
   * Adjusts the hue of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/hue)
   */
  hue?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Frame Interval
   *
   * Displays every Nth frame starting with the first frame.
   */
  interval?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Invert
   *
   * Inverts the colors on the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/invert)
   */
  invert?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Iptc Passthrough
   *
   * Determine if IPTC data should be passed for JPEG images.
   */
  iptc?: InputMaybe<ImgixParamsIptc>;
  /**
   * Jpg Progressive
   *
   * Specifies whether or not a jpg/jpeg uses progressive (true) or baseline (false)
   */
  jpgProgressive?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Animation Loop Count
   *
   * Specifies the number of times an animated image should repeat. A value of 0 means infinite looping.
   */
  loop?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Lossless Compression
   *
   * Specifies that the output image should be a lossless variant.
   *
   * Depends on: `fm=webp`, `fm=jxr`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/lossless)
   */
  lossless?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Watermark Image Url
   *
   * Specifies the location of the watermark image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark)
   */
  mark?: InputMaybe<Scalars['String']['input']>;
  /**
   * Watermark Alignment Mode
   *
   * Changes the watermark alignment relative to the parent image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-align)
   */
  markAlign?: InputMaybe<Array<ImgixParamsMarkAlign>>;
  /**
   * Watermark Alpha
   *
   * Changes the alpha of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-alpha)
   */
  markAlpha?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Watermark Base Url
   *
   * Changes base URL of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-base)
   */
  markBase?: InputMaybe<Scalars['String']['input']>;
  /**
   * Watermark Fit Mode
   *
   * Specifies the fit mode for watermark images.
   *
   * Depends on: `mark`, `markw`, `markh`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-fit)
   */
  markFit?: InputMaybe<ImgixParamsMarkFit>;
  /**
   * Watermark Height
   *
   * Adjusts the height of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-h)
   */
  markH?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Watermark Padding
   *
   * Applies padding to the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-pad)
   */
  markPad?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Watermark Rotation
   *
   * Rotates a watermark or tiled watermarks by a specified number of degrees.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-rot)
   */
  markRot?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Watermark Scale
   *
   * Adjusts the scale of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-scale)
   */
  markScale?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Watermark Tile
   *
   * Adds tiled watermark.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-tile)
   */
  markTile?: InputMaybe<ImgixParamsMarkTile>;
  /**
   * Watermark Width
   *
   * Adjusts the width of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-w)
   */
  markW?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Watermark X Position
   *
   * Adjusts the x-offset of the watermark image relative to its parent.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-x)
   */
  markX?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Watermark Y Position
   *
   * Adjusts the y-offset of the watermark image relative to its parent.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-y)
   */
  markY?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Mask Type
   *
   * Defines the type of mask and specifies the URL if that type is selected.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/mask)
   */
  mask?: InputMaybe<Scalars['String']['input']>;
  /**
   * Mask Background Color
   *
   * Colors the background of the transparent mask area of images
   *
   * Depends on: `mask`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/mask/mask-bg)
   */
  maskBg?: InputMaybe<Scalars['String']['input']>;
  /**
   * Maximum Height
   *
   * Specifies the maximum height of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/max-height)
   */
  maxH?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Maximum Width
   *
   * Specifies the maximum width of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/max-width)
   */
  maxW?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Minimum Height
   *
   * Specifies the minimum height of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/min-height)
   */
  minH?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Minimum Width
   *
   * Specifies the minimum width of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/min-width)
   */
  minW?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Monochrome
   *
   * Applies a monochrome effect to the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/monochrome)
   */
  monochrome?: InputMaybe<Scalars['String']['input']>;
  /**
   * Noise Reduction Bound
   *
   * Reduces the noise in an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/noise-reduction/nr)
   */
  nr?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Noise Reduction Sharpen
   *
   * Provides a threshold by which to sharpen an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/noise-reduction/nrs)
   */
  nrs?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Orientation
   *
   * Changes the image orientation.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/rotation/orient)
   */
  orient?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Padding
   *
   * Pads an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad)
   */
  pad?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Padding Bottom
   *
   * Sets bottom padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad-bottom)
   */
  padBottom?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Padding Left
   *
   * Sets left padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad-left)
   */
  padLeft?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Padding Right
   *
   * Sets right padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad-right)
   */
  padRight?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Padding Top
   *
   * Sets top padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad-top)
   */
  padTop?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Pdf Page Number
   *
   * Selects a page from a PDF for display.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/pdf/page)
   */
  page?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Color Palette Extraction
   *
   * Specifies an output format for palette-extraction.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/color-palette/palette)
   */
  palette?: InputMaybe<ImgixParamsPalette>;
  /**
   * Pdf Annotation
   *
   * Enables or disables PDF annotation.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/pdf/pdf-annotation)
   */
  pdfAnnotation?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Css Prefix
   *
   * Specifies a CSS prefix for all classes in palette-extraction.
   *
   * Depends on: `palette=css`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/color-palette/prefix)
   */
  prefix?: InputMaybe<Scalars['String']['input']>;
  /**
   * Pixellate
   *
   * Applies a pixelation effect to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/px)
   */
  px?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Output Quality
   *
   * Adjusts the quality of an output image.
   *
   * Depends on: `fm=jpg`, `fm=pjpg`, `fm=webp`, `fm=jxr`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/q)
   */
  q?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Source Rectangle Region
   *
   * Crops an image to a specified rectangle.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/rect)
   */
  rect?: InputMaybe<Scalars['String']['input']>;
  /**
   * Reverse
   *
   * Reverses the frame order on the source animation.
   */
  reverse?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Rotation
   *
   * Rotates an image by a specified number of degrees.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/rotation/rot)
   */
  rot?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Saturation
   *
   * Adjusts the saturation of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/sat)
   */
  sat?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Sepia Tone
   *
   * Applies a sepia effect to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/sepia)
   */
  sepia?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Shadow
   *
   * Adjusts the highlights of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/shad)
   */
  shad?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Sharpen
   *
   * Adjusts the sharpness of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/sharp)
   */
  sharp?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Frame Skip
   *
   * Skips every Nth frame starting with the first frame.
   */
  skip?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Bypasses any [DatoCMS Automatic Image Optimization](https://www.datocms.com/docs/cdn-settings/advanced-asset-settings) that might be set up for the project.
   *
   * Exercise caution when using this parameter, as it could significantly increase your bandwidth costs.
   */
  skipDefaultOptimizations?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Sanitize Svg
   *
   * Specifies whether to sanitize an SVG.
   */
  svgSanitize?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Transparency
   *
   * Adds checkerboard behind images which support transparency.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/transparency)
   */
  transparency?: InputMaybe<ImgixParamsTransparency>;
  /**
   * Trim Image
   *
   * Trims the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim)
   */
  trim?: InputMaybe<ImgixParamsTrim>;
  /**
   * Trim Color
   *
   * Specifies a trim color on a trim operation.
   *
   * Depends on: `trim=color`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-color)
   */
  trimColor?: InputMaybe<Scalars['String']['input']>;
  /**
   * Trim Mean Difference
   *
   * Specifies the mean difference on a trim operation.
   *
   * Depends on: `trim=auto`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-md)
   */
  trimMd?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Trim Padding
   *
   * Pads the area of the source image before trimming.
   *
   * Depends on: `trim`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-pad)
   */
  trimPad?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Trim Standard Deviation
   *
   * Specifies the standard deviation on a trim operation.
   *
   * Depends on: `trim=auto`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-sd)
   */
  trimSd?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Trim Tolerance
   *
   * Specifies the tolerance on a trim operation.
   *
   * Depends on: `trim=color`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-tol)
   */
  trimTol?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Text String
   *
   * Sets the text string to render.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt)
   */
  txt?: InputMaybe<Scalars['String']['input']>;
  /**
   * Text Align
   *
   * Sets the vertical and horizontal alignment of rendered text relative to the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-align)
   */
  txtAlign?: InputMaybe<Array<ImgixParamsTxtAlign>>;
  /**
   * Text Clipping Mode
   *
   * Sets the clipping properties of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-clip)
   */
  txtClip?: InputMaybe<Array<ImgixParamsTxtClip>>;
  /**
   * Text Color
   *
   * Specifies the color of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-color)
   */
  txtColor?: InputMaybe<Scalars['String']['input']>;
  /**
   * Text Fit Mode
   *
   * Specifies the fit approach for rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-fit)
   */
  txtFit?: InputMaybe<ImgixParamsTxtFit>;
  /**
   * Text Font
   *
   * Selects a font for rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-font)
   */
  txtFont?: InputMaybe<Scalars['String']['input']>;
  /**
   * Text Leading
   *
   * Sets the leading (line spacing) for rendered text. Only works on the multi-line text endpoint.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/typesetting/txt-lead)
   */
  txtLead?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Text Outline
   *
   * Outlines the rendered text with a specified color.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-line)
   */
  txtLine?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Text Outline Color
   *
   * Specifies a text outline color.
   *
   * Depends on: `txt`, `txtline`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-line-color)
   */
  txtLineColor?: InputMaybe<Scalars['String']['input']>;
  /**
   * Text Padding
   *
   * Specifies the padding (in device-independent pixels) between a textbox and the edges of the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-pad)
   */
  txtPad?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Text Shadow
   *
   * Applies a shadow to rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-shad)
   */
  txtShad?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Text Font Size
   *
   * Sets the font size of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-size)
   */
  txtSize?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Text Tracking
   *
   * Sets the tracking (letter spacing) for rendered text. Only works on the multi-line text endpoint.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/typesetting/txt-track)
   */
  txtTrack?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Text Width
   *
   * Sets the width of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-width)
   */
  txtWidth?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Text X Position
   *
   * Sets the horizontal (x) position of the text in pixels relative to the left edge of the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-x)
   */
  txtX?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Text Y Position
   *
   * Sets the vertical (y) position of the text in pixels relative to the top edge of the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-y)
   */
  txtY?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Super Resolution
   *
   * Uses generative AI fill to upscale low resolution images.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/super-resolution/upscale)
   */
  upscale?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Super Resolution Fallback
   *
   * Overrides default fallback behavior for super resolution failures
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/super-resolution/upscale-fallback)
   */
  upscaleFallback?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Unsharp Mask
   *
   * Sharpens the source image using an unsharp mask.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/usm)
   */
  usm?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Unsharp Mask Radius
   *
   * Specifies the radius for an unsharp mask operation.
   *
   * Depends on: `usm`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/usmrad)
   */
  usmrad?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Vibrance
   *
   * Adjusts the vibrance of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/vib)
   */
  vib?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Image Width
   *
   * Adjusts the width of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/w)
   */
  w?: InputMaybe<Scalars['FloatType']['input']>;
};

export enum ImgixParamsAuto {
  Compress = 'compress',
  Enhance = 'enhance',
  Format = 'format',
  Redeye = 'redeye'
}

export enum ImgixParamsBlendAlign {
  Bottom = 'bottom',
  Center = 'center',
  Left = 'left',
  Middle = 'middle',
  Right = 'right',
  Top = 'top'
}

export enum ImgixParamsBlendCrop {
  Bottom = 'bottom',
  Faces = 'faces',
  Left = 'left',
  Right = 'right',
  Top = 'top'
}

export enum ImgixParamsBlendFit {
  Clamp = 'clamp',
  Clip = 'clip',
  Crop = 'crop',
  Max = 'max',
  Scale = 'scale'
}

export enum ImgixParamsBlendMode {
  Burn = 'burn',
  Color = 'color',
  Darken = 'darken',
  Difference = 'difference',
  Dodge = 'dodge',
  Exclusion = 'exclusion',
  Hardlight = 'hardlight',
  Hue = 'hue',
  Lighten = 'lighten',
  Luminosity = 'luminosity',
  Multiply = 'multiply',
  Normal = 'normal',
  Overlay = 'overlay',
  Saturation = 'saturation',
  Screen = 'screen',
  Softlight = 'softlight'
}

export enum ImgixParamsBlendSize {
  Inherit = 'inherit'
}

export enum ImgixParamsCh {
  Dpr = 'dpr',
  SaveData = 'saveData',
  Width = 'width'
}

export enum ImgixParamsCrop {
  Bottom = 'bottom',
  Edges = 'edges',
  Entropy = 'entropy',
  Faces = 'faces',
  Focalpoint = 'focalpoint',
  Left = 'left',
  Right = 'right',
  Top = 'top'
}

export enum ImgixParamsCs {
  Adobergb1998 = 'adobergb1998',
  Srgb = 'srgb',
  Strip = 'strip',
  Tinysrgb = 'tinysrgb'
}

export enum ImgixParamsFill {
  Blur = 'blur',
  Gen = 'gen',
  Generative = 'generative',
  Gradient = 'gradient',
  Solid = 'solid'
}

export enum ImgixParamsFillGenPos {
  Bottom = 'bottom',
  Center = 'center',
  Left = 'left',
  Middle = 'middle',
  Right = 'right',
  Top = 'top'
}

export enum ImgixParamsFillGradientCs {
  Hsl = 'hsl',
  Lch = 'lch',
  Linear = 'linear',
  Oklab = 'oklab',
  Srgb = 'srgb'
}

export enum ImgixParamsFillGradientLinearDirection {
  Bottom = 'bottom',
  Left = 'left',
  Right = 'right',
  Top = 'top'
}

export enum ImgixParamsFillGradientType {
  Linear = 'linear',
  Radial = 'radial'
}

export enum ImgixParamsFit {
  Clamp = 'clamp',
  Clip = 'clip',
  Crop = 'crop',
  Facearea = 'facearea',
  Fill = 'fill',
  Fillmax = 'fillmax',
  Max = 'max',
  Min = 'min',
  Scale = 'scale'
}

export enum ImgixParamsFlip {
  H = 'h',
  Hv = 'hv',
  V = 'v'
}

export enum ImgixParamsFm {
  Avif = 'avif',
  Blurhash = 'blurhash',
  Gif = 'gif',
  Jp2 = 'jp2',
  Jpg = 'jpg',
  Json = 'json',
  Jxr = 'jxr',
  Mp4 = 'mp4',
  Pjpg = 'pjpg',
  Png = 'png',
  Png8 = 'png8',
  Png32 = 'png32',
  Webm = 'webm',
  Webp = 'webp'
}

export enum ImgixParamsIptc {
  Allow = 'allow',
  Block = 'block'
}

export enum ImgixParamsMarkAlign {
  Bottom = 'bottom',
  Center = 'center',
  Left = 'left',
  Middle = 'middle',
  Right = 'right',
  Top = 'top'
}

export enum ImgixParamsMarkFit {
  Clip = 'clip',
  Crop = 'crop',
  Fill = 'fill',
  Max = 'max',
  Scale = 'scale'
}

export enum ImgixParamsMarkTile {
  Grid = 'grid'
}

export enum ImgixParamsPalette {
  Css = 'css',
  Json = 'json'
}

export enum ImgixParamsTransparency {
  Grid = 'grid'
}

export enum ImgixParamsTrim {
  Auto = 'auto',
  Color = 'color'
}

export enum ImgixParamsTxtAlign {
  Bottom = 'bottom',
  Center = 'center',
  Left = 'left',
  Middle = 'middle',
  Right = 'right',
  Top = 'top'
}

export enum ImgixParamsTxtClip {
  Ellipsis = 'ellipsis',
  End = 'end',
  Middle = 'middle',
  Start = 'start'
}

export enum ImgixParamsTxtFit {
  Max = 'max'
}

/** Specifies how to filter by usage */
export type InUseFilter = {
  /** Search uploads that are currently used by some record or not */
  eq?: InputMaybe<Scalars['BooleanType']['input']>;
};

/** Specifies how to filter by ID */
export type ItemIdFilter = {
  /** Search the record with the specified ID */
  eq?: InputMaybe<Scalars['ItemId']['input']>;
  /** Search records with the specified IDs */
  in?: InputMaybe<Array<InputMaybe<Scalars['ItemId']['input']>>>;
  /** Exclude the record with the specified ID */
  neq?: InputMaybe<Scalars['ItemId']['input']>;
  /** Search records that do not have the specified IDs */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ItemId']['input']>>>;
};

export enum ItemStatus {
  Draft = 'draft',
  Published = 'published',
  Updated = 'updated'
}

export type LayoutModelMenuField = PageLinkRecord | PanelLinkRecord;

export type LayoutModelMenuFieldListListNonNullMultiLocaleField = {
  __typename: 'LayoutModelMenuFieldListListNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: Array<LayoutModelMenuField>;
};

/** Record of type 🧩 Layout (layout) */
export type LayoutRecord = RecordInterface & {
  __typename: 'LayoutRecord';
  _allFooterLegalLinksLocales?: Maybe<Array<LegalPageRecordListListNonNullMultiLocaleField>>;
  _allFooterLinkColumnsLocales?: Maybe<Array<LinkColumnRecordListListNonNullMultiLocaleField>>;
  _allFooterSubtitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allMenuLocales?: Maybe<Array<LayoutModelMenuFieldListListNonNullMultiLocaleField>>;
  _allNewsletterSubscriptionLocales?: Maybe<Array<NewsletterSubscriptionRecordNonNullMultiLocaleField>>;
  _allSocialMediaLocales?: Maybe<Array<SocialMediaLinkRecordListListNonNullMultiLocaleField>>;
  _allTaglineHtmlLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  footerLegalLinks: Array<LegalPageRecord>;
  footerLinkColumns: Array<LinkColumnRecord>;
  footerSubtitle: Scalars['String']['output'];
  id: Scalars['ItemId']['output'];
  menu: Array<LayoutModelMenuField>;
  newsletterSubscription: NewsletterSubscriptionRecord;
  socialMedia: Array<SocialMediaLinkRecord>;
  taglineHtml: Scalars['String']['output'];
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecord_AllFooterLegalLinksLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecord_AllFooterLinkColumnsLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecord_AllFooterSubtitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecord_AllMenuLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecord_AllNewsletterSubscriptionLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecord_AllSocialMediaLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecord_AllTaglineHtmlLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecordFooterLegalLinksArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecordFooterLinkColumnsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecordFooterSubtitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecordMenuArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecordNewsletterSubscriptionArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecordSocialMediaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecordTaglineHtmlArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LegalPageModelContentField = {
  __typename: 'LegalPageModelContentField';
  blocks: Array<Scalars['String']['output']>;
  links: Array<Scalars['String']['output']>;
  value: Scalars['JsonField']['output'];
};

export type LegalPageModelContentFieldNonNullMultiLocaleField = {
  __typename: 'LegalPageModelContentFieldNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: LegalPageModelContentField;
};

export type LegalPageModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<LegalPageModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LegalPageModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  content?: InputMaybe<StructuredTextFilter>;
  id?: InputMaybe<ItemIdFilter>;
  slug?: InputMaybe<SlugFilter>;
  title?: InputMaybe<StringFilter>;
};

export enum LegalPageModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Record of type 🏛️ Legal Page (legal_page) */
export type LegalPageRecord = RecordInterface & {
  __typename: 'LegalPageRecord';
  _allContentLocales?: Maybe<Array<LegalPageModelContentFieldNonNullMultiLocaleField>>;
  _allSlugLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  content: LegalPageModelContentField;
  id: Scalars['ItemId']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
};


/** Record of type 🏛️ Legal Page (legal_page) */
export type LegalPageRecord_AllContentLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏛️ Legal Page (legal_page) */
export type LegalPageRecord_AllSlugLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏛️ Legal Page (legal_page) */
export type LegalPageRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏛️ Legal Page (legal_page) */
export type LegalPageRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏛️ Legal Page (legal_page) */
export type LegalPageRecordContentArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏛️ Legal Page (legal_page) */
export type LegalPageRecordSlugArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏛️ Legal Page (legal_page) */
export type LegalPageRecordTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type LegalPageRecordListListNonNullMultiLocaleField = {
  __typename: 'LegalPageRecordListListNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: Array<LegalPageRecord>;
};

/** Block of type 🔡 Link Column (link_column) */
export type LinkColumnRecord = RecordInterface & {
  __typename: 'LinkColumnRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  linkSet: Array<LinkSetRecord>;
};


/** Block of type 🔡 Link Column (link_column) */
export type LinkColumnRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type LinkColumnRecordListListNonNullMultiLocaleField = {
  __typename: 'LinkColumnRecordListListNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: Array<LinkColumnRecord>;
};

export type LinkSetModelLinkItemsField = CustomLinkRecord | PageLinkRecord;

/** Block of type 🔡 Link Set (link_set) */
export type LinkSetRecord = RecordInterface & {
  __typename: 'LinkSetRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  linkItems: Array<LinkSetModelLinkItemsField>;
  setTitle: Scalars['String']['output'];
};


/** Block of type 🔡 Link Set (link_set) */
export type LinkSetRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter by locale */
export type LocalesFilter = {
  /** Filter records that are localized in all the specified locales */
  allIn?: InputMaybe<Array<SiteLocale>>;
  /** Filter records that are localized in at least one of the specified locales */
  anyIn?: InputMaybe<Array<SiteLocale>>;
  /** Filter records that are not localized in any of the specified locales */
  notIn?: InputMaybe<Array<SiteLocale>>;
};

export type MenuLinkModelLinkItemField = CustomLinkRecord | PageLinkRecord;

export type MenuLinkModelSubLinkItemsField = CustomLinkRecord | MenuLinkRecord | PageLinkRecord;

/** Block of type 🤝 Menu Link (menu_link) */
export type MenuLinkRecord = RecordInterface & {
  __typename: 'MenuLinkRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  linkItem: MenuLinkModelLinkItemField;
  subLinkItems: Array<MenuLinkModelSubLinkItemsField>;
};


/** Block of type 🤝 Menu Link (menu_link) */
export type MenuLinkRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export enum MuxThumbnailFormatType {
  Gif = 'gif',
  Jpg = 'jpg',
  Png = 'png'
}

export type NewsletterModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<NewsletterModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<NewsletterModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _locales?: InputMaybe<LocalesFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  buttonText?: InputMaybe<StringFilter>;
  id?: InputMaybe<ItemIdFilter>;
  inputPlaceholder?: InputMaybe<StringFilter>;
  optText?: InputMaybe<TextFilter>;
  remoteFormUrl?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
};

export enum NewsletterModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  ButtonTextAsc = 'buttonText_ASC',
  ButtonTextDesc = 'buttonText_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InputPlaceholderAsc = 'inputPlaceholder_ASC',
  InputPlaceholderDesc = 'inputPlaceholder_DESC',
  RemoteFormUrlAsc = 'remoteFormUrl_ASC',
  RemoteFormUrlDesc = 'remoteFormUrl_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Record of type 📬 Newsletter (newsletter) */
export type NewsletterRecord = RecordInterface & {
  __typename: 'NewsletterRecord';
  _allButtonTextLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allConfirmationLocales?: Maybe<Array<NotificationRecordNonNullMultiLocaleField>>;
  _allExceptionLocales?: Maybe<Array<NotificationRecordNonNullMultiLocaleField>>;
  _allInputPlaceholderLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allOptTextLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allRemoteFormUrlLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  buttonText: Scalars['String']['output'];
  confirmation: NotificationRecord;
  exception: NotificationRecord;
  id: Scalars['ItemId']['output'];
  inputPlaceholder?: Maybe<Scalars['String']['output']>;
  optText?: Maybe<Scalars['String']['output']>;
  remoteFormUrl: Scalars['String']['output'];
  title: Scalars['String']['output'];
};


/** Record of type 📬 Newsletter (newsletter) */
export type NewsletterRecord_AllButtonTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📬 Newsletter (newsletter) */
export type NewsletterRecord_AllConfirmationLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📬 Newsletter (newsletter) */
export type NewsletterRecord_AllExceptionLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📬 Newsletter (newsletter) */
export type NewsletterRecord_AllInputPlaceholderLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📬 Newsletter (newsletter) */
export type NewsletterRecord_AllOptTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 📬 Newsletter (newsletter) */
export type NewsletterRecord_AllRemoteFormUrlLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📬 Newsletter (newsletter) */
export type NewsletterRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📬 Newsletter (newsletter) */
export type NewsletterRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📬 Newsletter (newsletter) */
export type NewsletterRecordButtonTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📬 Newsletter (newsletter) */
export type NewsletterRecordConfirmationArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📬 Newsletter (newsletter) */
export type NewsletterRecordExceptionArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📬 Newsletter (newsletter) */
export type NewsletterRecordInputPlaceholderArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📬 Newsletter (newsletter) */
export type NewsletterRecordOptTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 📬 Newsletter (newsletter) */
export type NewsletterRecordRemoteFormUrlArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📬 Newsletter (newsletter) */
export type NewsletterRecordTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type 📫 Newsletter Subscription (newsletter_subscription) */
export type NewsletterSubscriptionRecord = RecordInterface & {
  __typename: 'NewsletterSubscriptionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  newsletter: NewsletterRecord;
};


/** Block of type 📫 Newsletter Subscription (newsletter_subscription) */
export type NewsletterSubscriptionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type NewsletterSubscriptionRecordNonNullMultiLocaleField = {
  __typename: 'NewsletterSubscriptionRecordNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: NewsletterSubscriptionRecord;
};

/** Block of type 🔔 Notification (notification) */
export type NotificationRecord = RecordInterface & {
  __typename: 'NotificationRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  optTitle?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
};


/** Block of type 🔔 Notification (notification) */
export type NotificationRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Block of type 🔔 Notification (notification) */
export type NotificationRecordTextArgs = {
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NotificationRecordNonNullMultiLocaleField = {
  __typename: 'NotificationRecordNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: NotificationRecord;
};

/** Specifies how to filter by image orientation */
export type OrientationFilter = {
  /** Search uploads with the specified orientation */
  eq?: InputMaybe<UploadOrientation>;
  /** Exclude uploads with the specified orientation */
  neq?: InputMaybe<UploadOrientation>;
};

/** Block of type 🔝 Page Header (page_header) */
export type PageHeaderRecord = RecordInterface & {
  __typename: 'PageHeaderRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  colorMode: Scalars['BooleanType']['output'];
  ctaList: Array<ButtonRecord>;
  id: Scalars['ItemId']['output'];
  optImage?: Maybe<ImageFileField>;
  optIntro?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};


/** Block of type 🔝 Page Header (page_header) */
export type PageHeaderRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Block of type 🔝 Page Header (page_header) */
export type PageHeaderRecordOptIntroArgs = {
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Block of type 🔝 Page Header (page_header) */
export type PageHeaderRecordTitleArgs = {
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PageHeaderRecordMultiLocaleField = {
  __typename: 'PageHeaderRecordMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value?: Maybe<PageHeaderRecord>;
};

export type PageLinkModelLinkField = CategoryPageRecord | PageRecord | SeriesPageRecord | SolutionPageRecord;

/** Block of type 🤝 Page Link (page_link) */
export type PageLinkRecord = RecordInterface & {
  __typename: 'PageLinkRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  link: PageLinkModelLinkField;
  text: Scalars['String']['output'];
};


/** Block of type 🤝 Page Link (page_link) */
export type PageLinkRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type PageModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<PageModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PageModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _locales?: InputMaybe<LocalesFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  id?: InputMaybe<ItemIdFilter>;
  seoMeta?: InputMaybe<SeoFilter>;
  slug?: InputMaybe<SlugFilter>;
  title?: InputMaybe<StringFilter>;
};

export enum PageModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type PageModelSectionsField = AllBrandsSectionRecord | BannerCarouselSectionRecord | BannerSectionRecord | CallToActionSectionRecord | CenteredTextSectionRecord | ContactTeasersSectionRecord | ContentSectionRecord | DealersSectionRecord | FaqSectionRecord | IconFeaturesSectionRecord | ImageFeaturesSectionRecord | SectionDividerRecord | SeriesSectionRecord | TeaserCarouselSectionRecord | TeaserSectionRecord | TechnicalDetailsSectionRecord | TextAndImageSectionRecord | TitleAndTextSectionRecord | UspListSectionRecord | UspScrollSectionRecord;

export type PageModelSectionsFieldListListNonNullMultiLocaleField = {
  __typename: 'PageModelSectionsFieldListListNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: Array<PageModelSectionsField>;
};

/** Record of type 🏡 Page (page) */
export type PageRecord = RecordInterface & {
  __typename: 'PageRecord';
  _allHeaderLocales?: Maybe<Array<PageHeaderRecordMultiLocaleField>>;
  _allSectionsLocales?: Maybe<Array<PageModelSectionsFieldListListNonNullMultiLocaleField>>;
  _allSeoMetaLocales?: Maybe<Array<SeoFieldMultiLocaleField>>;
  _allSlugLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allTitleItemLocales?: Maybe<Array<TitleItemRecordMultiLocaleField>>;
  _allTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  header?: Maybe<PageHeaderRecord>;
  id: Scalars['ItemId']['output'];
  sections: Array<PageModelSectionsField>;
  seoMeta?: Maybe<SeoField>;
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  titleItem?: Maybe<TitleItemRecord>;
};


/** Record of type 🏡 Page (page) */
export type PageRecord_AllHeaderLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏡 Page (page) */
export type PageRecord_AllSectionsLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏡 Page (page) */
export type PageRecord_AllSeoMetaLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏡 Page (page) */
export type PageRecord_AllSlugLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏡 Page (page) */
export type PageRecord_AllTitleItemLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏡 Page (page) */
export type PageRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏡 Page (page) */
export type PageRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏡 Page (page) */
export type PageRecordHeaderArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏡 Page (page) */
export type PageRecordSectionsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏡 Page (page) */
export type PageRecordSeoMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏡 Page (page) */
export type PageRecordSlugArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏡 Page (page) */
export type PageRecordTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏡 Page (page) */
export type PageRecordTitleItemArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type PanelLinkModelCallToActionField = CustomLinkRecord | PageLinkRecord;

export type PanelLinkModelMainLinkField = CustomLinkRecord | PageLinkRecord;

export type PanelLinkModelSubLinksColumn1Field = CustomLinkRecord | MenuLinkRecord | PageLinkRecord;

export type PanelLinkModelSubLinksColumn2Field = CustomLinkRecord | MenuLinkRecord | PageLinkRecord;

export type PanelLinkModelSubLinksColumn3Field = CustomLinkRecord | MenuLinkRecord | PageLinkRecord;

/** Block of type 🤝 Panel Link (panel_link) */
export type PanelLinkRecord = RecordInterface & {
  __typename: 'PanelLinkRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  callToAction?: Maybe<PanelLinkModelCallToActionField>;
  id: Scalars['ItemId']['output'];
  mainLink: PanelLinkModelMainLinkField;
  sidePanel?: Maybe<SidePanelRecord>;
  subLinksColumn1: Array<PanelLinkModelSubLinksColumn1Field>;
  subLinksColumn2: Array<PanelLinkModelSubLinksColumn2Field>;
  subLinksColumn3: Array<PanelLinkModelSubLinksColumn3Field>;
};


/** Block of type 🤝 Panel Link (panel_link) */
export type PanelLinkRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter by position (sorted and tree-like collections) */
export type PositionFilter = {
  /** Search for records with an exact match */
  eq?: InputMaybe<Scalars['IntType']['input']>;
  /** Filter records with a value that's strictly greater than the one specified */
  gt?: InputMaybe<Scalars['IntType']['input']>;
  /** Filter records with a value that's greater than or equal to the one specified */
  gte?: InputMaybe<Scalars['IntType']['input']>;
  /** Filter records with a value that's less than the one specified */
  lt?: InputMaybe<Scalars['IntType']['input']>;
  /** Filter records with a value that's less or equal than the one specified */
  lte?: InputMaybe<Scalars['IntType']['input']>;
  /** Exclude records with an exact match */
  neq?: InputMaybe<Scalars['IntType']['input']>;
};

/** Specifies how to filter by publication datetime */
export type PublishedAtFilter = {
  /** Filter records with a value that's within the specified minute range. Seconds and milliseconds are truncated from the argument. */
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records with a value that's strictly greater than the one specified. Seconds and milliseconds are truncated from the argument. */
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's greater than or equal to than the one specified. Seconds and milliseconds are truncated from the argument. */
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's less than the one specified. Seconds and milliseconds are truncated from the argument. */
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's less or equal than the one specified. Seconds and milliseconds are truncated from the argument. */
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's outside the specified minute range. Seconds and milliseconds are truncated from the argument. */
  neq?: InputMaybe<Scalars['DateTime']['input']>;
};

/** The query root for this schema */
export type Query = {
  __typename: 'Query';
  /** Returns meta information regarding a record collection */
  _allBrandsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allCallToActionsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allCategoryPagesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allCountriesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allDealersMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allFaqsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allLegalPagesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allNewslettersMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allPagesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allSeriesPagesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allSolutionPagesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allTechnicalDetailsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allTranslationsMeta: CollectionMetadata;
  /** Returns meta information regarding an assets collection */
  _allUploadsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allUspsMeta: CollectionMetadata;
  /** Returns the single instance record */
  _site: Site;
  /** Returns a collection of records */
  allBrands: Array<BrandRecord>;
  /** Returns a collection of records */
  allCallToActions: Array<CallToActionRecord>;
  /** Returns a collection of records */
  allCategoryPages: Array<CategoryPageRecord>;
  /** Returns a collection of records */
  allCountries: Array<CountryRecord>;
  /** Returns a collection of records */
  allDealers: Array<DealerRecord>;
  /** Returns a collection of records */
  allFaqs: Array<FaqRecord>;
  /** Returns a collection of records */
  allLegalPages: Array<LegalPageRecord>;
  /** Returns a collection of records */
  allNewsletters: Array<NewsletterRecord>;
  /** Returns a collection of records */
  allPages: Array<PageRecord>;
  /** Returns a collection of records */
  allSeriesPages: Array<SeriesPageRecord>;
  /** Returns a collection of records */
  allSolutionPages: Array<SolutionPageRecord>;
  /** Returns a collection of records */
  allTechnicalDetails: Array<TechnicalDetailRecord>;
  /** Returns a collection of records */
  allTranslations: Array<TranslationRecord>;
  /** Returns a collection of assets */
  allUploads: Array<FileField>;
  /** Returns a collection of records */
  allUsps: Array<UspRecord>;
  /** Returns a specific record */
  brand?: Maybe<BrandRecord>;
  /** Returns a specific record */
  callToAction?: Maybe<CallToActionRecord>;
  /** Returns a specific record */
  categoryPage?: Maybe<CategoryPageRecord>;
  /** Returns the single instance record */
  contactForm?: Maybe<ContactFormRecord>;
  /** Returns a specific record */
  country?: Maybe<CountryRecord>;
  /** Returns a specific record */
  dealer?: Maybe<DealerRecord>;
  /** Returns the single instance record */
  error404?: Maybe<Error404Record>;
  /** Returns a specific record */
  faq?: Maybe<FaqRecord>;
  /** Returns the single instance record */
  layout?: Maybe<LayoutRecord>;
  /** Returns a specific record */
  legalPage?: Maybe<LegalPageRecord>;
  /** Returns a specific record */
  newsletter?: Maybe<NewsletterRecord>;
  /** Returns a specific record */
  page?: Maybe<PageRecord>;
  /** Returns a specific record */
  seriesPage?: Maybe<SeriesPageRecord>;
  /** Returns a specific record */
  solutionPage?: Maybe<SolutionPageRecord>;
  /** Returns a specific record */
  technicalDetail?: Maybe<TechnicalDetailRecord>;
  /** Returns a specific record */
  translation?: Maybe<TranslationRecord>;
  /** Returns a specific asset */
  upload?: Maybe<FileField>;
  /** Returns a specific record */
  usp?: Maybe<UspRecord>;
};


/** The query root for this schema */
export type Query_AllBrandsMetaArgs = {
  filter?: InputMaybe<BrandModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_AllCallToActionsMetaArgs = {
  filter?: InputMaybe<CallToActionModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_AllCategoryPagesMetaArgs = {
  filter?: InputMaybe<CategoryPageModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_AllCountriesMetaArgs = {
  filter?: InputMaybe<CountryModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_AllDealersMetaArgs = {
  filter?: InputMaybe<DealerModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_AllFaqsMetaArgs = {
  filter?: InputMaybe<FaqModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_AllLegalPagesMetaArgs = {
  filter?: InputMaybe<LegalPageModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_AllNewslettersMetaArgs = {
  filter?: InputMaybe<NewsletterModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_AllPagesMetaArgs = {
  filter?: InputMaybe<PageModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_AllSeriesPagesMetaArgs = {
  filter?: InputMaybe<SeriesPageModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_AllSolutionPagesMetaArgs = {
  filter?: InputMaybe<SolutionPageModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_AllTechnicalDetailsMetaArgs = {
  filter?: InputMaybe<TechnicalDetailModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_AllTranslationsMetaArgs = {
  filter?: InputMaybe<TranslationModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_AllUploadsMetaArgs = {
  filter?: InputMaybe<UploadFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_AllUspsMetaArgs = {
  filter?: InputMaybe<UspModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_SiteArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type QueryAllBrandsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<BrandModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BrandModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryAllCallToActionsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<CallToActionModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<CallToActionModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryAllCategoryPagesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<CategoryPageModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<CategoryPageModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryAllCountriesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<CountryModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<CountryModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryAllDealersArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<DealerModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<DealerModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryAllFaqsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<FaqModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<FaqModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryAllLegalPagesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<LegalPageModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<LegalPageModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryAllNewslettersArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<NewsletterModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<NewsletterModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryAllPagesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<PageModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<PageModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryAllSeriesPagesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<SeriesPageModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<SeriesPageModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryAllSolutionPagesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<SolutionPageModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<SolutionPageModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryAllTechnicalDetailsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TechnicalDetailModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TechnicalDetailModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryAllTranslationsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TranslationModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TranslationModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryAllUploadsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<UploadFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<UploadOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryAllUspsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<UspModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<UspModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryBrandArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<BrandModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BrandModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryCallToActionArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<CallToActionModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<CallToActionModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryCategoryPageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<CategoryPageModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<CategoryPageModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryContactFormArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type QueryCountryArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<CountryModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<CountryModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryDealerArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<DealerModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<DealerModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryError404Args = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type QueryFaqArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<FaqModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<FaqModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryLayoutArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type QueryLegalPageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<LegalPageModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<LegalPageModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryNewsletterArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<NewsletterModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<NewsletterModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryPageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<PageModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<PageModelOrderBy>>>;
};


/** The query root for this schema */
export type QuerySeriesPageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<SeriesPageModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<SeriesPageModelOrderBy>>>;
};


/** The query root for this schema */
export type QuerySolutionPageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<SolutionPageModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<SolutionPageModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryTechnicalDetailArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TechnicalDetailModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TechnicalDetailModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryTranslationArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TranslationModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TranslationModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryUploadArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<UploadFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<UploadOrderBy>>>;
};


/** The query root for this schema */
export type QueryUspArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<UspModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<UspModelOrderBy>>>;
};

export type RecordInterface = {
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
};


export type RecordInterface_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter by upload type */
export type ResolutionFilter = {
  /** Search uploads with the specified resolution */
  eq?: InputMaybe<ResolutionType>;
  /** Search uploads with the specified resolutions */
  in?: InputMaybe<Array<InputMaybe<ResolutionType>>>;
  /** Exclude uploads with the specified resolution */
  neq?: InputMaybe<ResolutionType>;
  /** Search uploads without the specified resolutions */
  notIn?: InputMaybe<Array<InputMaybe<ResolutionType>>>;
};

export enum ResolutionType {
  Icon = 'icon',
  Large = 'large',
  Medium = 'medium',
  Small = 'small'
}

export type ResponsiveImage = {
  __typename: 'ResponsiveImage';
  alt?: Maybe<Scalars['String']['output']>;
  aspectRatio: Scalars['FloatType']['output'];
  base64?: Maybe<Scalars['String']['output']>;
  bgColor?: Maybe<Scalars['String']['output']>;
  height: Scalars['IntType']['output'];
  sizes: Scalars['String']['output'];
  src: Scalars['String']['output'];
  srcSet: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  webpSrcSet: Scalars['String']['output'];
  width: Scalars['IntType']['output'];
};

/** Block of type ➖ Section Divider (section_divider) */
export type SectionDividerRecord = RecordInterface & {
  __typename: 'SectionDividerRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
};


/** Block of type ➖ Section Divider (section_divider) */
export type SectionDividerRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type SeoField = {
  __typename: 'SeoField';
  description?: Maybe<Scalars['String']['output']>;
  image?: Maybe<FileField>;
  noIndex?: Maybe<Scalars['BooleanType']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  twitterCard?: Maybe<Scalars['String']['output']>;
};

export type SeoFieldMultiLocaleField = {
  __typename: 'SeoFieldMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value?: Maybe<SeoField>;
};

/** Specifies how to filter SEO meta tags fields */
export type SeoFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
};

export type SeriesPageModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<SeriesPageModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SeriesPageModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _locales?: InputMaybe<LocalesFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  code?: InputMaybe<StringFilter>;
  id?: InputMaybe<ItemIdFilter>;
  position?: InputMaybe<PositionFilter>;
  seoMeta?: InputMaybe<SeoFilter>;
  slug?: InputMaybe<SlugFilter>;
  teaserImage?: InputMaybe<FileFilter>;
  teaserLinkText?: InputMaybe<StringFilter>;
  teaserText?: InputMaybe<TextFilter>;
  teaserTitle?: InputMaybe<TextFilter>;
  title?: InputMaybe<StringFilter>;
};

export enum SeriesPageModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  TeaserLinkTextAsc = 'teaserLinkText_ASC',
  TeaserLinkTextDesc = 'teaserLinkText_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type SeriesPageModelSectionsField = BannerCarouselSectionRecord | CenteredTextSectionRecord | FaqSectionRecord | IconFeaturesSectionRecord | TextAndImageSectionRecord;

export type SeriesPageModelSectionsFieldListListNonNullMultiLocaleField = {
  __typename: 'SeriesPageModelSectionsFieldListListNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: Array<SeriesPageModelSectionsField>;
};

/** Record of type 🏡 Series Page (series_page) */
export type SeriesPageRecord = RecordInterface & {
  __typename: 'SeriesPageRecord';
  _allHeaderLocales?: Maybe<Array<PageHeaderRecordMultiLocaleField>>;
  _allSectionsLocales?: Maybe<Array<SeriesPageModelSectionsFieldListListNonNullMultiLocaleField>>;
  _allSeoMetaLocales?: Maybe<Array<SeoFieldMultiLocaleField>>;
  _allSlugLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allTeaserLinkTextLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allTeaserTextLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allTeaserTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  code: Scalars['String']['output'];
  header?: Maybe<PageHeaderRecord>;
  id: Scalars['ItemId']['output'];
  position?: Maybe<Scalars['IntType']['output']>;
  sections: Array<SeriesPageModelSectionsField>;
  seoMeta?: Maybe<SeoField>;
  slug: Scalars['String']['output'];
  teaserImage: ImageFileField;
  teaserLinkText: Scalars['String']['output'];
  teaserText: Scalars['String']['output'];
  teaserTitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
};


/** Record of type 🏡 Series Page (series_page) */
export type SeriesPageRecord_AllHeaderLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏡 Series Page (series_page) */
export type SeriesPageRecord_AllSectionsLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏡 Series Page (series_page) */
export type SeriesPageRecord_AllSeoMetaLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏡 Series Page (series_page) */
export type SeriesPageRecord_AllSlugLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏡 Series Page (series_page) */
export type SeriesPageRecord_AllTeaserLinkTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏡 Series Page (series_page) */
export type SeriesPageRecord_AllTeaserTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🏡 Series Page (series_page) */
export type SeriesPageRecord_AllTeaserTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🏡 Series Page (series_page) */
export type SeriesPageRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏡 Series Page (series_page) */
export type SeriesPageRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏡 Series Page (series_page) */
export type SeriesPageRecordHeaderArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏡 Series Page (series_page) */
export type SeriesPageRecordSectionsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏡 Series Page (series_page) */
export type SeriesPageRecordSeoMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏡 Series Page (series_page) */
export type SeriesPageRecordSlugArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏡 Series Page (series_page) */
export type SeriesPageRecordTeaserLinkTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏡 Series Page (series_page) */
export type SeriesPageRecordTeaserTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🏡 Series Page (series_page) */
export type SeriesPageRecordTeaserTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🏡 Series Page (series_page) */
export type SeriesPageRecordTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type 🏟️ Series Section (series_section) */
export type SeriesSectionRecord = RecordInterface & {
  __typename: 'SeriesSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  series: Array<SeriesPageRecord>;
  showAllLink: CustomLinkRecord;
  title: Scalars['String']['output'];
};


/** Block of type 🏟️ Series Section (series_section) */
export type SeriesSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Block of type 🏟️ Series Section (series_section) */
export type SeriesSectionRecordTitleArgs = {
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SidePanelModelLinkField = CustomLinkRecord | PageLinkRecord;

/** Block of type 🤝 Side Panel (side_panel) */
export type SidePanelRecord = RecordInterface & {
  __typename: 'SidePanelRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  image: ImageFileField;
  link: SidePanelModelLinkField;
  title: Scalars['String']['output'];
};


/** Block of type 🤝 Side Panel (side_panel) */
export type SidePanelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type Site = {
  __typename: 'Site';
  favicon?: Maybe<FileField>;
  faviconMetaTags: Array<Tag>;
  globalSeo?: Maybe<GlobalSeoField>;
  locales: Array<SiteLocale>;
  noIndex?: Maybe<Scalars['BooleanType']['output']>;
};


export type SiteFaviconMetaTagsArgs = {
  variants?: InputMaybe<Array<InputMaybe<FaviconType>>>;
};


export type SiteGlobalSeoArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export enum SiteLocale {
  En = 'en',
  Fr = 'fr',
  Nl = 'nl'
}

/** Specifies how to filter Slug fields */
export type SlugFilter = {
  /** Search for records with an exact match */
  eq?: InputMaybe<Scalars['String']['input']>;
  /** Filter records that have one of the specified slugs */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude records with an exact match */
  neq?: InputMaybe<Scalars['String']['input']>;
  /** Filter records that do have one of the specified slugs */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Block of type 🌎 Social Media Link (social_media_link) */
export type SocialMediaLinkRecord = RecordInterface & {
  __typename: 'SocialMediaLinkRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  platform: Scalars['String']['output'];
  url: Scalars['String']['output'];
};


/** Block of type 🌎 Social Media Link (social_media_link) */
export type SocialMediaLinkRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type SocialMediaLinkRecordListListNonNullMultiLocaleField = {
  __typename: 'SocialMediaLinkRecordListListNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: Array<SocialMediaLinkRecord>;
};

export type SolutionPageModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<SolutionPageModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SolutionPageModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _locales?: InputMaybe<LocalesFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  code?: InputMaybe<StringFilter>;
  id?: InputMaybe<ItemIdFilter>;
  seoMeta?: InputMaybe<SeoFilter>;
  slug?: InputMaybe<SlugFilter>;
  title?: InputMaybe<StringFilter>;
};

export enum SolutionPageModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type SolutionPageModelSectionsField = AllBrandsSectionRecord | BannerSectionRecord | ContentSectionRecord | FaqSectionRecord | TeaserSectionRecord | UspListSectionRecord;

export type SolutionPageModelSectionsFieldListListNonNullMultiLocaleField = {
  __typename: 'SolutionPageModelSectionsFieldListListNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: Array<SolutionPageModelSectionsField>;
};

/** Record of type 🏡 Solution Page (solution_page) */
export type SolutionPageRecord = RecordInterface & {
  __typename: 'SolutionPageRecord';
  _allSectionsLocales?: Maybe<Array<SolutionPageModelSectionsFieldListListNonNullMultiLocaleField>>;
  _allSeoMetaLocales?: Maybe<Array<SeoFieldMultiLocaleField>>;
  _allSlugLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  code: Scalars['String']['output'];
  id: Scalars['ItemId']['output'];
  sections: Array<SolutionPageModelSectionsField>;
  seoMeta?: Maybe<SeoField>;
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
};


/** Record of type 🏡 Solution Page (solution_page) */
export type SolutionPageRecord_AllSectionsLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏡 Solution Page (solution_page) */
export type SolutionPageRecord_AllSeoMetaLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏡 Solution Page (solution_page) */
export type SolutionPageRecord_AllSlugLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏡 Solution Page (solution_page) */
export type SolutionPageRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏡 Solution Page (solution_page) */
export type SolutionPageRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏡 Solution Page (solution_page) */
export type SolutionPageRecordSectionsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏡 Solution Page (solution_page) */
export type SolutionPageRecordSeoMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏡 Solution Page (solution_page) */
export type SolutionPageRecordSlugArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏡 Solution Page (solution_page) */
export type SolutionPageRecordTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type SplitItemModelOptLinkItemField = CustomLinkRecord | PageLinkRecord;

/** Block of type ✂️ Split Item (split_item) */
export type SplitItemRecord = RecordInterface & {
  __typename: 'SplitItemRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  image: ImageFileField;
  imagePosition: Scalars['BooleanType']['output'];
  optLinkItem?: Maybe<SplitItemModelOptLinkItemField>;
  optTitle?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
};


/** Block of type ✂️ Split Item (split_item) */
export type SplitItemRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Block of type ✂️ Split Item (split_item) */
export type SplitItemRecordTextArgs = {
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Specifies how to filter by status */
export type StatusFilter = {
  /** Search the record with the specified status */
  eq?: InputMaybe<ItemStatus>;
  /** Search records with the specified statuses */
  in?: InputMaybe<Array<InputMaybe<ItemStatus>>>;
  /** Exclude the record with the specified status */
  neq?: InputMaybe<ItemStatus>;
  /** Search records without the specified statuses */
  notIn?: InputMaybe<Array<InputMaybe<ItemStatus>>>;
};

/** Specifies how to filter Single-line string fields */
export type StringFilter = {
  /** Search for records with an exact match */
  eq?: InputMaybe<Scalars['String']['input']>;
  /** Filter records with the specified field defined (i.e. with any value) or not [DEPRECATED] */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records that equal one of the specified values */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filter records with the specified field set as blank (null or empty string) */
  isBlank?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records with the specified field present (neither null, nor empty string) */
  isPresent?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude records with an exact match */
  neq?: InputMaybe<Scalars['String']['input']>;
  /** Filter records that do not equal one of the specified values */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude records based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

export type StringMatchesFilter = {
  caseSensitive?: InputMaybe<Scalars['BooleanType']['input']>;
  pattern: Scalars['String']['input'];
  regexp?: InputMaybe<Scalars['BooleanType']['input']>;
};

export type StringMultiLocaleField = {
  __typename: 'StringMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value?: Maybe<Scalars['String']['output']>;
};

export type StringNonNullMultiLocaleField = {
  __typename: 'StringNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: Scalars['String']['output'];
};

/** Specifies how to filter Structured Text fields values */
export type StructuredTextFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not [DEPRECATED] */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records with the specified field set as blank (null or single empty paragraph) */
  isBlank?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records with the specified field present (neither null, nor empty string) */
  isPresent?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude records based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

export type Tag = {
  __typename: 'Tag';
  attributes?: Maybe<Scalars['MetaTagAttributes']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  tag: Scalars['String']['output'];
};

/** Block of type 🎠 Teaser Carousel Section (teaser_carousel_section) */
export type TeaserCarouselSectionRecord = RecordInterface & {
  __typename: 'TeaserCarouselSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  sectionTitle?: Maybe<Scalars['String']['output']>;
  slidesPerView?: Maybe<Scalars['IntType']['output']>;
  teasers: Array<TeaserRecord>;
  variant: Scalars['BooleanType']['output'];
};


/** Block of type 🎠 Teaser Carousel Section (teaser_carousel_section) */
export type TeaserCarouselSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type TeaserModelLinkField = CustomLinkRecord | PageLinkRecord;

/** Block of type 📎 Teaser (teaser) */
export type TeaserRecord = RecordInterface & {
  __typename: 'TeaserRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  image: ImageFileField;
  link: TeaserModelLinkField;
  optText?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};


/** Block of type 📎 Teaser (teaser) */
export type TeaserRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Block of type 📎 Teaser (teaser) */
export type TeaserRecordOptTextArgs = {
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Block of type 📎 Teaser (teaser) */
export type TeaserRecordTitleArgs = {
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Block of type 📎 Teaser Section (teaser_section) */
export type TeaserSectionRecord = RecordInterface & {
  __typename: 'TeaserSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  sectionTitle?: Maybe<Scalars['String']['output']>;
  teasers: Array<TeaserRecord>;
};


/** Block of type 📎 Teaser Section (teaser_section) */
export type TeaserSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type TechnicalDetailModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<TechnicalDetailModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<TechnicalDetailModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _locales?: InputMaybe<LocalesFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  id?: InputMaybe<ItemIdFilter>;
  info?: InputMaybe<TextFilter>;
  position?: InputMaybe<PositionFilter>;
  title?: InputMaybe<StringFilter>;
};

export enum TechnicalDetailModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Record of type 📋 Technical Detail (technical_detail) */
export type TechnicalDetailRecord = RecordInterface & {
  __typename: 'TechnicalDetailRecord';
  _allInfoLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  info: Scalars['String']['output'];
  position?: Maybe<Scalars['IntType']['output']>;
  title: Scalars['String']['output'];
};


/** Record of type 📋 Technical Detail (technical_detail) */
export type TechnicalDetailRecord_AllInfoLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 📋 Technical Detail (technical_detail) */
export type TechnicalDetailRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📋 Technical Detail (technical_detail) */
export type TechnicalDetailRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📋 Technical Detail (technical_detail) */
export type TechnicalDetailRecordInfoArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 📋 Technical Detail (technical_detail) */
export type TechnicalDetailRecordTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type TechnicalDetailsSectionModelDownloadLinkField = CustomLinkRecord | PageLinkRecord;

/** Block of type 📋 Technical Details Section (technical_details_section) */
export type TechnicalDetailsSectionRecord = RecordInterface & {
  __typename: 'TechnicalDetailsSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  downloadLink: TechnicalDetailsSectionModelDownloadLinkField;
  id: Scalars['ItemId']['output'];
  image: ImageFileField;
  sectionTitle?: Maybe<Scalars['String']['output']>;
  technicalDetails: Array<TechnicalDetailRecord>;
  title: Scalars['String']['output'];
};


/** Block of type 📋 Technical Details Section (technical_details_section) */
export type TechnicalDetailsSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Block of type 📋 Technical Details Section (technical_details_section) */
export type TechnicalDetailsSectionRecordTitleArgs = {
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Block of type 🧾 Text And Image Section (text_and_image_section) */
export type TextAndImageSectionRecord = RecordInterface & {
  __typename: 'TextAndImageSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  image: ImageFileField;
  imageSide: Scalars['BooleanType']['output'];
  sectionTitle?: Maybe<Scalars['String']['output']>;
  titleAndText: TitleAndTextRecord;
};


/** Block of type 🧾 Text And Image Section (text_and_image_section) */
export type TextAndImageSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter text fields */
export type TextFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not [DEPRECATED] */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records with the specified field set as blank (null or empty string) */
  isBlank?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records with the specified field present (neither null, nor empty string) */
  isPresent?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude records based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

/** Block of type 📝 Text Item (text_item) */
export type TextItemRecord = RecordInterface & {
  __typename: 'TextItemRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  text: Scalars['String']['output'];
};


/** Block of type 📝 Text Item (text_item) */
export type TextItemRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type TextItemRecordListListNonNullMultiLocaleField = {
  __typename: 'TextItemRecordListListNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: Array<TextItemRecord>;
};

export type TitleAndTextModelLinkField = CustomLinkRecord | PageLinkRecord;

/** Block of type 🧾 Title And Text (title_and_text) */
export type TitleAndTextRecord = RecordInterface & {
  __typename: 'TitleAndTextRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  link?: Maybe<TitleAndTextModelLinkField>;
  linkVariant: Scalars['BooleanType']['output'];
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
};


/** Block of type 🧾 Title And Text (title_and_text) */
export type TitleAndTextRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Block of type 🧾 Title And Text (title_and_text) */
export type TitleAndTextRecordTextArgs = {
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Block of type 🧾 Title And Text (title_and_text) */
export type TitleAndTextRecordTitleArgs = {
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Block of type 🧾 Title And Text Section (title_and_text_section) */
export type TitleAndTextSectionRecord = RecordInterface & {
  __typename: 'TitleAndTextSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  colorMode: Scalars['BooleanType']['output'];
  extraMargin: Scalars['BooleanType']['output'];
  id: Scalars['ItemId']['output'];
  sectionTitle?: Maybe<Scalars['String']['output']>;
  titleAndText: TitleAndTextRecord;
};


/** Block of type 🧾 Title And Text Section (title_and_text_section) */
export type TitleAndTextSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type 🏷️ Title Item (title_item) */
export type TitleItemRecord = RecordInterface & {
  __typename: 'TitleItemRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  color: Scalars['BooleanType']['output'];
  id: Scalars['ItemId']['output'];
  optImage?: Maybe<ImageFileField>;
  optIntro?: Maybe<Scalars['String']['output']>;
  optSubTitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};


/** Block of type 🏷️ Title Item (title_item) */
export type TitleItemRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Block of type 🏷️ Title Item (title_item) */
export type TitleItemRecordOptIntroArgs = {
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TitleItemRecordMultiLocaleField = {
  __typename: 'TitleItemRecordMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value?: Maybe<TitleItemRecord>;
};

export type TranslationModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<TranslationModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<TranslationModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  comment?: InputMaybe<StringFilter>;
  id?: InputMaybe<ItemIdFilter>;
  name?: InputMaybe<StringFilter>;
  value?: InputMaybe<StringFilter>;
};

export enum TranslationModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  CommentAsc = 'comment_ASC',
  CommentDesc = 'comment_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC'
}

/** Record of type 🌍 Translation (translation) */
export type TranslationRecord = RecordInterface & {
  __typename: 'TranslationRecord';
  _allValueLocales?: Maybe<Array<StringMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  id: Scalars['ItemId']['output'];
  name: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};


/** Record of type 🌍 Translation (translation) */
export type TranslationRecord_AllValueLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🌍 Translation (translation) */
export type TranslationRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🌍 Translation (translation) */
export type TranslationRecordValueArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter by upload type */
export type TypeFilter = {
  /** Search uploads with the specified type */
  eq?: InputMaybe<UploadType>;
  /** Search uploads with the specified types */
  in?: InputMaybe<Array<InputMaybe<UploadType>>>;
  /** Exclude uploads with the specified type */
  neq?: InputMaybe<UploadType>;
  /** Search uploads without the specified types */
  notIn?: InputMaybe<Array<InputMaybe<UploadType>>>;
};

/** Specifies how to filter by update datetime */
export type UpdatedAtFilter = {
  /** Filter records with a value that's within the specified minute range. Seconds and milliseconds are truncated from the argument. */
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records with a value that's strictly greater than the one specified. Seconds and milliseconds are truncated from the argument. */
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's greater than or equal to than the one specified. Seconds and milliseconds are truncated from the argument. */
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's less than the one specified. Seconds and milliseconds are truncated from the argument. */
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's less or equal than the one specified. Seconds and milliseconds are truncated from the argument. */
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's outside the specified minute range. Seconds and milliseconds are truncated from the argument. */
  neq?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Specifies how to filter by default alt */
export type UploadAltFilter = {
  /** Search the uploads with the specified alt */
  eq?: InputMaybe<Scalars['String']['input']>;
  /** Filter uploads with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Search uploads with the specified values as default alt */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude the uploads with the specified alt */
  neq?: InputMaybe<Scalars['String']['input']>;
  /** Search uploads that do not have the specified values as default alt */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by auhtor */
export type UploadAuthorFilter = {
  /** Filter uploads with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by basename */
export type UploadBasenameFilter = {
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by colors */
export type UploadColorsFilter = {
  /** Filter uploads that have all of the specified colors */
  allIn?: InputMaybe<Array<InputMaybe<ColorBucketType>>>;
  /** Filter uploads that have at least one of the specified colors */
  anyIn?: InputMaybe<Array<InputMaybe<ColorBucketType>>>;
  /** Filter uploads that have the specified colors */
  contains?: InputMaybe<ColorBucketType>;
  /** Search for uploads with an exact match */
  eq?: InputMaybe<Array<InputMaybe<ColorBucketType>>>;
  /** Filter uploads that do not have any of the specified colors */
  notIn?: InputMaybe<Array<InputMaybe<ColorBucketType>>>;
};

/** Specifies how to filter by copyright */
export type UploadCopyrightFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by creation datetime */
export type UploadCreatedAtFilter = {
  /** Search for uploads with an exact match */
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter uploads with a value that's strictly greater than the one specified */
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter uploads with a value that's greater than or equal to the one specified */
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter uploads with a value that's less than the one specified */
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter uploads with a value that's less or equal than the one specified */
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Exclude uploads with an exact match */
  neq?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Specifies how to filter by filename */
export type UploadFilenameFilter = {
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

export type UploadFilter = {
  AND?: InputMaybe<Array<InputMaybe<UploadFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<UploadFilter>>>;
  _createdAt?: InputMaybe<UploadCreatedAtFilter>;
  _updatedAt?: InputMaybe<UploadUpdatedAtFilter>;
  alt?: InputMaybe<UploadAltFilter>;
  author?: InputMaybe<UploadAuthorFilter>;
  basename?: InputMaybe<UploadBasenameFilter>;
  colors?: InputMaybe<UploadColorsFilter>;
  copyright?: InputMaybe<UploadCopyrightFilter>;
  filename?: InputMaybe<UploadFilenameFilter>;
  format?: InputMaybe<UploadFormatFilter>;
  height?: InputMaybe<UploadHeightFilter>;
  id?: InputMaybe<UploadIdFilter>;
  inUse?: InputMaybe<InUseFilter>;
  md5?: InputMaybe<UploadMd5Filter>;
  mimeType?: InputMaybe<UploadMimeTypeFilter>;
  notes?: InputMaybe<UploadNotesFilter>;
  orientation?: InputMaybe<OrientationFilter>;
  resolution?: InputMaybe<ResolutionFilter>;
  size?: InputMaybe<UploadSizeFilter>;
  smartTags?: InputMaybe<UploadTagsFilter>;
  tags?: InputMaybe<UploadTagsFilter>;
  title?: InputMaybe<UploadTitleFilter>;
  type?: InputMaybe<TypeFilter>;
  width?: InputMaybe<UploadWidthFilter>;
};

/** Specifies how to filter by format */
export type UploadFormatFilter = {
  /** Search the asset with the specified format */
  eq?: InputMaybe<Scalars['String']['input']>;
  /** Search assets with the specified formats */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude the asset with the specified format */
  neq?: InputMaybe<Scalars['String']['input']>;
  /** Search assets that do not have the specified formats */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Specifies how to filter by height */
export type UploadHeightFilter = {
  /** Search assets with the specified height */
  eq?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets larger than the specified height */
  gt?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets larger or equal to the specified height */
  gte?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets smaller than the specified height */
  lt?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets larger or equal to the specified height */
  lte?: InputMaybe<Scalars['IntType']['input']>;
  /** Search assets that do not have the specified height */
  neq?: InputMaybe<Scalars['IntType']['input']>;
};

/** Specifies how to filter by ID */
export type UploadIdFilter = {
  /** Search the asset with the specified ID */
  eq?: InputMaybe<Scalars['UploadId']['input']>;
  /** Search assets with the specified IDs */
  in?: InputMaybe<Array<InputMaybe<Scalars['UploadId']['input']>>>;
  /** Exclude the asset with the specified ID */
  neq?: InputMaybe<Scalars['UploadId']['input']>;
  /** Search assets that do not have the specified IDs */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['UploadId']['input']>>>;
};

/** Specifies how to filter by MD5 */
export type UploadMd5Filter = {
  /** Search the asset with the specified MD5 */
  eq?: InputMaybe<Scalars['String']['input']>;
  /** Search assets with the specified MD5s */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude the asset with the specified MD5 */
  neq?: InputMaybe<Scalars['String']['input']>;
  /** Search assets that do not have the specified MD5s */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Specifies how to filter by mime type */
export type UploadMimeTypeFilter = {
  /** Search the asset with the specified mime type */
  eq?: InputMaybe<Scalars['String']['input']>;
  /** Search assets with the specified mime types */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude the asset with the specified mime type */
  neq?: InputMaybe<Scalars['String']['input']>;
  /** Search assets that do not have the specified mime types */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by notes */
export type UploadNotesFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

export enum UploadOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  BasenameAsc = 'basename_ASC',
  BasenameDesc = 'basename_DESC',
  FilenameAsc = 'filename_ASC',
  FilenameDesc = 'filename_DESC',
  FormatAsc = 'format_ASC',
  FormatDesc = 'format_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MimeTypeAsc = 'mimeType_ASC',
  MimeTypeDesc = 'mimeType_DESC',
  ResolutionAsc = 'resolution_ASC',
  ResolutionDesc = 'resolution_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC'
}

export enum UploadOrientation {
  Landscape = 'landscape',
  Portrait = 'portrait',
  Square = 'square'
}

/** Specifies how to filter by size */
export type UploadSizeFilter = {
  /** Search assets with the specified size (in bytes) */
  eq?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets larger than the specified size (in bytes) */
  gt?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets larger or equal to the specified size (in bytes) */
  gte?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets smaller than the specified size (in bytes) */
  lt?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets larger or equal to the specified size (in bytes) */
  lte?: InputMaybe<Scalars['IntType']['input']>;
  /** Search assets that do not have the specified size (in bytes) */
  neq?: InputMaybe<Scalars['IntType']['input']>;
};

/** Specifies how to filter by tags */
export type UploadTagsFilter = {
  /** Filter uploads linked to all of the specified tags */
  allIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter uploads linked to at least one of the specified tags */
  anyIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter uploads linked to the specified tag */
  contains?: InputMaybe<Scalars['String']['input']>;
  /** Search for uploads with an exact match */
  eq?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter uploads not linked to any of the specified tags */
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Specifies how to filter by default title */
export type UploadTitleFilter = {
  /** Search the asset with the specified title */
  eq?: InputMaybe<Scalars['String']['input']>;
  /** Filter assets with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Search assets with the specified as default title */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude the asset with the specified title */
  neq?: InputMaybe<Scalars['String']['input']>;
  /** Search assets that do not have the specified as default title */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

export enum UploadType {
  Archive = 'archive',
  Audio = 'audio',
  Image = 'image',
  Pdfdocument = 'pdfdocument',
  Presentation = 'presentation',
  Richtext = 'richtext',
  Spreadsheet = 'spreadsheet',
  Video = 'video'
}

/** Specifies how to filter by update datetime */
export type UploadUpdatedAtFilter = {
  /** Search for uploads with an exact match */
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter uploads with a value that's strictly greater than the one specified */
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter uploads with a value that's greater than or equal to the one specified */
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter uploads with a value that's less than the one specified */
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter uploads with a value that's less or equal than the one specified */
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Exclude uploads with an exact match */
  neq?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UploadVideoField = {
  __typename: 'UploadVideoField';
  alt?: Maybe<Scalars['String']['output']>;
  blurUpThumb?: Maybe<Scalars['String']['output']>;
  blurhash?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  framerate?: Maybe<Scalars['Int']['output']>;
  height: Scalars['IntType']['output'];
  mp4Url?: Maybe<Scalars['String']['output']>;
  muxAssetId: Scalars['String']['output'];
  muxPlaybackId: Scalars['String']['output'];
  streamingUrl: Scalars['String']['output'];
  thumbhash?: Maybe<Scalars['String']['output']>;
  thumbnailUrl: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  width: Scalars['IntType']['output'];
};


export type UploadVideoFieldAltArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


export type UploadVideoFieldBlurUpThumbArgs = {
  imgixParams?: InputMaybe<ImgixParams>;
  punch?: Scalars['Float']['input'];
  quality?: Scalars['Int']['input'];
  size?: Scalars['Int']['input'];
};


export type UploadVideoFieldMp4UrlArgs = {
  exactRes?: InputMaybe<VideoMp4Res>;
  res?: InputMaybe<VideoMp4Res>;
};


export type UploadVideoFieldThumbnailUrlArgs = {
  format?: InputMaybe<MuxThumbnailFormatType>;
};


export type UploadVideoFieldTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter by width */
export type UploadWidthFilter = {
  /** Search assets with the specified width */
  eq?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets larger than the specified width */
  gt?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets larger or equal to the specified width */
  gte?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets smaller than the specified width */
  lt?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets larger or equal to the specified width */
  lte?: InputMaybe<Scalars['IntType']['input']>;
  /** Search assets that do not have the specified width */
  neq?: InputMaybe<Scalars['IntType']['input']>;
};

/** Block of type ⭐ USP List Section (usp_list_section) */
export type UspListSectionRecord = RecordInterface & {
  __typename: 'UspListSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  colorMode: Scalars['BooleanType']['output'];
  id: Scalars['ItemId']['output'];
  sectionTitle?: Maybe<Scalars['String']['output']>;
  sellingPoints: Array<UspRecord>;
};


/** Block of type ⭐ USP List Section (usp_list_section) */
export type UspListSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type UspModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<UspModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<UspModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _locales?: InputMaybe<LocalesFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  id?: InputMaybe<ItemIdFilter>;
  image?: InputMaybe<FileFilter>;
  internalTitle?: InputMaybe<StringFilter>;
  position?: InputMaybe<PositionFilter>;
  text?: InputMaybe<TextFilter>;
  title?: InputMaybe<TextFilter>;
};

export enum UspModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalTitleAsc = 'internalTitle_ASC',
  InternalTitleDesc = 'internalTitle_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC'
}

/** Record of type ⭐ USP (usp) */
export type UspRecord = RecordInterface & {
  __typename: 'UspRecord';
  _allTextLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  image?: Maybe<ImageFileField>;
  internalTitle?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['IntType']['output']>;
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
};


/** Record of type ⭐ USP (usp) */
export type UspRecord_AllTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type ⭐ USP (usp) */
export type UspRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type ⭐ USP (usp) */
export type UspRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type ⭐ USP (usp) */
export type UspRecordTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type ⭐ USP (usp) */
export type UspRecordTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UspScrollSectionModelCallToActionLinkField = CustomLinkRecord | PageLinkRecord;

/** Block of type ⭐ USP Scroll Section (usp_scroll_section) */
export type UspScrollSectionRecord = RecordInterface & {
  __typename: 'UspScrollSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  callToActionLink: UspScrollSectionModelCallToActionLinkField;
  callToActionText: Scalars['String']['output'];
  id: Scalars['ItemId']['output'];
  sectionTitle?: Maybe<Scalars['String']['output']>;
  sellingPoints: Array<UspRecord>;
  title: Scalars['String']['output'];
};


/** Block of type ⭐ USP Scroll Section (usp_scroll_section) */
export type UspScrollSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Block of type ⭐ USP Scroll Section (usp_scroll_section) */
export type UspScrollSectionRecordTitleArgs = {
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum VideoMp4Res {
  High = 'high',
  Low = 'low',
  Medium = 'medium'
}

export type FocalPoint = {
  __typename: 'focalPoint';
  x: Scalars['FloatType']['output'];
  y: Scalars['FloatType']['output'];
};

export type CategoriesDetailQuery_categoryPage_CategoryPageRecord_sections_AllBrandsSectionRecord = { __typename: 'AllBrandsSectionRecord' };

export type CategoriesDetailQuery_categoryPage_CategoryPageRecord_sections_BannerSectionRecord = { __typename: 'BannerSectionRecord' };

export type CategoriesDetailQuery_categoryPage_CategoryPageRecord_sections_ContentSectionRecord = (
  { __typename: 'ContentSectionRecord' }
  & Custom_ContentSectionRecordFragment
);

export type CategoriesDetailQuery_categoryPage_CategoryPageRecord_sections_FaqSectionRecord = { __typename: 'FaqSectionRecord' };

export type CategoriesDetailQuery_categoryPage_CategoryPageRecord_sections_TeaserSectionRecord = { __typename: 'TeaserSectionRecord' };

export type CategoriesDetailQuery_categoryPage_CategoryPageRecord_sections_UspListSectionRecord = { __typename: 'UspListSectionRecord' };

export type CategoriesDetailQuery_categoryPage_CategoryPageRecord_sections = CategoriesDetailQuery_categoryPage_CategoryPageRecord_sections_AllBrandsSectionRecord | CategoriesDetailQuery_categoryPage_CategoryPageRecord_sections_BannerSectionRecord | CategoriesDetailQuery_categoryPage_CategoryPageRecord_sections_ContentSectionRecord | CategoriesDetailQuery_categoryPage_CategoryPageRecord_sections_FaqSectionRecord | CategoriesDetailQuery_categoryPage_CategoryPageRecord_sections_TeaserSectionRecord | CategoriesDetailQuery_categoryPage_CategoryPageRecord_sections_UspListSectionRecord;

export type CategoriesDetailQuery_categoryPage_CategoryPageRecord__seoMetaTags_Tag = { __typename: 'Tag', content?: string | null, tag: string, attributes?: Record<string, string> | null };

export type CategoriesDetailQuery_categoryPage_CategoryPageRecord__allSlugLocales_StringNonNullMultiLocaleField = { __typename: 'StringNonNullMultiLocaleField', locale?: SiteLocale | null, value: string };

export type CategoriesDetailQuery_categoryPage_CategoryPageRecord = { __typename: 'CategoryPageRecord', id: string, code: string, title: string, slug: string, sections: Array<CategoriesDetailQuery_categoryPage_CategoryPageRecord_sections>, _seoMetaTags: Array<CategoriesDetailQuery_categoryPage_CategoryPageRecord__seoMetaTags_Tag>, _allSlugLocales?: Array<CategoriesDetailQuery_categoryPage_CategoryPageRecord__allSlugLocales_StringNonNullMultiLocaleField> | null };

export type CategoriesDetailQuery_Query = { categoryPage?: CategoriesDetailQuery_categoryPage_CategoryPageRecord | null };


export type CategoriesDetailQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<SiteLocale>;
}>;


export type CategoriesDetailQuery = CategoriesDetailQuery_Query;

export type Error404Query_error404_Error404Record_body_Error404ModelBodyField_blocks_ImageBlockRecord = (
  { __typename: 'ImageBlockRecord' }
  & Custom_ImageBlockRecordFragment
);

export type Error404Query_error404_Error404Record_body_Error404ModelBodyField = { __typename: 'Error404ModelBodyField', links: Array<string>, value: unknown, blocks: Array<Error404Query_error404_Error404Record_body_Error404ModelBodyField_blocks_ImageBlockRecord> };

export type Error404Query_error404_Error404Record__seoMetaTags_Tag = { __typename: 'Tag', content?: string | null, tag: string, attributes?: Record<string, string> | null };

export type Error404Query_error404_Error404Record = { __typename: 'Error404Record', id: string, body: Error404Query_error404_Error404Record_body_Error404ModelBodyField, _seoMetaTags: Array<Error404Query_error404_Error404Record__seoMetaTags_Tag> };

export type Error404Query_Query = { error404?: Error404Query_error404_Error404Record | null };


export type Error404QueryVariables = Exact<{
  locale?: InputMaybe<SiteLocale>;
}>;


export type Error404Query = Error404Query_Query;

export type ContactFormQuery_contactForm_ContactFormRecord_countries_CountryRecord = { __typename: 'CountryRecord', id: string, name: string, isoCode: string, phoneNumberCode: string };

export type ContactFormQuery_contactForm_ContactFormRecord_dealers_DealerRecord = { __typename: 'DealerRecord', id: string, name: string, address: string, phoneNumber: string, eMailAddress: string };

export type ContactFormQuery_contactForm_ContactFormRecord_subjects_TextItemRecord = { __typename: 'TextItemRecord', text: string };

export type ContactFormQuery_contactForm_ContactFormRecord_confirmation_NotificationRecord = { __typename: 'NotificationRecord', optTitle?: string | null, text: string };

export type ContactFormQuery_contactForm_ContactFormRecord_exception_NotificationRecord = { __typename: 'NotificationRecord', optTitle?: string | null, text: string };

export type ContactFormQuery_contactForm_ContactFormRecord = { __typename: 'ContactFormRecord', id: string, titleDealer: string, titleInfo: string, titleReason: string, labelCompanyName: string, labelCompanyNumber: string, labelDealerName: string, labelEMailAddress: string, labelFirstName: string, labelLastName: string, labelMessage: string, labelPhoneCountryCode: string, labelPhoneNumber: string, labelSubject: string, placeholderSubject: string, helpTextMessage: string, actionSubmit: string, countries: Array<ContactFormQuery_contactForm_ContactFormRecord_countries_CountryRecord>, dealers: Array<ContactFormQuery_contactForm_ContactFormRecord_dealers_DealerRecord>, subjects: Array<ContactFormQuery_contactForm_ContactFormRecord_subjects_TextItemRecord>, confirmation: ContactFormQuery_contactForm_ContactFormRecord_confirmation_NotificationRecord, exception: ContactFormQuery_contactForm_ContactFormRecord_exception_NotificationRecord };

export type ContactFormQuery_Query = { contactForm?: ContactFormQuery_contactForm_ContactFormRecord | null };


export type ContactFormQueryVariables = Exact<{
  locale?: InputMaybe<SiteLocale>;
}>;


export type ContactFormQuery = ContactFormQuery_Query;

export type LayoutQuery_layout_LayoutRecord_menu_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type LayoutQuery_layout_LayoutRecord_menu_PanelLinkRecord = (
  { __typename: 'PanelLinkRecord' }
  & Custom_PanelLinkRecordFragment
);

export type LayoutQuery_layout_LayoutRecord_menu = LayoutQuery_layout_LayoutRecord_menu_PageLinkRecord | LayoutQuery_layout_LayoutRecord_menu_PanelLinkRecord;

export type LayoutQuery_layout_LayoutRecord_footerLinkColumns_LinkColumnRecord_linkSet_LinkSetRecord_linkItems_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type LayoutQuery_layout_LayoutRecord_footerLinkColumns_LinkColumnRecord_linkSet_LinkSetRecord_linkItems_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type LayoutQuery_layout_LayoutRecord_footerLinkColumns_LinkColumnRecord_linkSet_LinkSetRecord_linkItems = LayoutQuery_layout_LayoutRecord_footerLinkColumns_LinkColumnRecord_linkSet_LinkSetRecord_linkItems_CustomLinkRecord | LayoutQuery_layout_LayoutRecord_footerLinkColumns_LinkColumnRecord_linkSet_LinkSetRecord_linkItems_PageLinkRecord;

export type LayoutQuery_layout_LayoutRecord_footerLinkColumns_LinkColumnRecord_linkSet_LinkSetRecord = { __typename: 'LinkSetRecord', _modelApiKey: string, id: string, setTitle: string, linkItems: Array<LayoutQuery_layout_LayoutRecord_footerLinkColumns_LinkColumnRecord_linkSet_LinkSetRecord_linkItems> };

export type LayoutQuery_layout_LayoutRecord_footerLinkColumns_LinkColumnRecord = { __typename: 'LinkColumnRecord', _modelApiKey: string, id: string, linkSet: Array<LayoutQuery_layout_LayoutRecord_footerLinkColumns_LinkColumnRecord_linkSet_LinkSetRecord> };

export type LayoutQuery_layout_LayoutRecord_newsletterSubscription_NewsletterSubscriptionRecord_newsletter_NewsletterRecord_confirmation_NotificationRecord = { __typename: 'NotificationRecord', optTitle?: string | null, text: string };

export type LayoutQuery_layout_LayoutRecord_newsletterSubscription_NewsletterSubscriptionRecord_newsletter_NewsletterRecord_exception_NotificationRecord = { __typename: 'NotificationRecord', optTitle?: string | null, text: string };

export type LayoutQuery_layout_LayoutRecord_newsletterSubscription_NewsletterSubscriptionRecord_newsletter_NewsletterRecord = { __typename: 'NewsletterRecord', id: string, title: string, optText?: string | null, inputPlaceholder?: string | null, buttonText: string, remoteFormUrl: string, confirmation: LayoutQuery_layout_LayoutRecord_newsletterSubscription_NewsletterSubscriptionRecord_newsletter_NewsletterRecord_confirmation_NotificationRecord, exception: LayoutQuery_layout_LayoutRecord_newsletterSubscription_NewsletterSubscriptionRecord_newsletter_NewsletterRecord_exception_NotificationRecord };

export type LayoutQuery_layout_LayoutRecord_newsletterSubscription_NewsletterSubscriptionRecord = { __typename: 'NewsletterSubscriptionRecord', id: string, newsletter: LayoutQuery_layout_LayoutRecord_newsletterSubscription_NewsletterSubscriptionRecord_newsletter_NewsletterRecord };

export type LayoutQuery_layout_LayoutRecord_footerLegalLinks_LegalPageRecord = { __typename: 'LegalPageRecord', title: string, slug: string };

export type LayoutQuery_layout_LayoutRecord_socialMedia_SocialMediaLinkRecord = { __typename: 'SocialMediaLinkRecord', platform: string, url: string };

export type LayoutQuery_layout_LayoutRecord = { __typename: 'LayoutRecord', taglineHtml: string, footerSubtitle: string, menu: Array<LayoutQuery_layout_LayoutRecord_menu>, footerLinkColumns: Array<LayoutQuery_layout_LayoutRecord_footerLinkColumns_LinkColumnRecord>, newsletterSubscription: LayoutQuery_layout_LayoutRecord_newsletterSubscription_NewsletterSubscriptionRecord, footerLegalLinks: Array<LayoutQuery_layout_LayoutRecord_footerLegalLinks_LegalPageRecord>, socialMedia: Array<LayoutQuery_layout_LayoutRecord_socialMedia_SocialMediaLinkRecord> };

export type LayoutQuery_Query = { layout?: LayoutQuery_layout_LayoutRecord | null };


export type LayoutQueryVariables = Exact<{
  locale?: InputMaybe<SiteLocale>;
}>;


export type LayoutQuery = LayoutQuery_Query;

export type LegalQuery_legalPage_LegalPageRecord_content_LegalPageModelContentField = { __typename: 'LegalPageModelContentField', blocks: Array<string>, links: Array<string>, value: unknown };

export type LegalQuery_legalPage_LegalPageRecord__seoMetaTags_Tag = { __typename: 'Tag', attributes?: Record<string, string> | null, content?: string | null, tag: string };

export type LegalQuery_legalPage_LegalPageRecord__allSlugLocales_StringNonNullMultiLocaleField = { __typename: 'StringNonNullMultiLocaleField', locale?: SiteLocale | null, value: string };

export type LegalQuery_legalPage_LegalPageRecord = { __typename: 'LegalPageRecord', id: string, title: string, slug: string, content: LegalQuery_legalPage_LegalPageRecord_content_LegalPageModelContentField, _seoMetaTags: Array<LegalQuery_legalPage_LegalPageRecord__seoMetaTags_Tag>, _allSlugLocales?: Array<LegalQuery_legalPage_LegalPageRecord__allSlugLocales_StringNonNullMultiLocaleField> | null };

export type LegalQuery_Query = { legalPage?: LegalQuery_legalPage_LegalPageRecord | null };


export type LegalQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<SiteLocale>;
}>;


export type LegalQuery = LegalQuery_Query;

export type PageQuery_page_PageRecord_header_PageHeaderRecord = (
  { __typename: 'PageHeaderRecord' }
  & Custom_PageHeaderRecordFragment
);

export type PageQuery_page_PageRecord_sections_AllBrandsSectionRecord = { __typename: 'AllBrandsSectionRecord' };

export type PageQuery_page_PageRecord_sections_BannerCarouselSectionRecord = (
  { __typename: 'BannerCarouselSectionRecord' }
  & Custom_BannerCarouselSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_BannerSectionRecord = { __typename: 'BannerSectionRecord' };

export type PageQuery_page_PageRecord_sections_CallToActionSectionRecord = (
  { __typename: 'CallToActionSectionRecord' }
  & Custom_CallToActionSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_CenteredTextSectionRecord = (
  { __typename: 'CenteredTextSectionRecord' }
  & Custom_CenteredTextSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_ContactTeasersSectionRecord = (
  { __typename: 'ContactTeasersSectionRecord' }
  & Custom_ContactTeasersSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_ContentSectionRecord = (
  { __typename: 'ContentSectionRecord' }
  & Custom_ContentSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_DealersSectionRecord = (
  { __typename: 'DealersSectionRecord' }
  & Custom_DealersSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_FaqSectionRecord = { __typename: 'FaqSectionRecord' };

export type PageQuery_page_PageRecord_sections_IconFeaturesSectionRecord = (
  { __typename: 'IconFeaturesSectionRecord' }
  & Custom_IconFeaturesSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_ImageFeaturesSectionRecord = (
  { __typename: 'ImageFeaturesSectionRecord' }
  & Custom_ImageFeaturesSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_SectionDividerRecord = (
  { __typename: 'SectionDividerRecord' }
  & Custom_SectionDividerRecordFragment
);

export type PageQuery_page_PageRecord_sections_SeriesSectionRecord = { __typename: 'SeriesSectionRecord' };

export type PageQuery_page_PageRecord_sections_TeaserCarouselSectionRecord = (
  { __typename: 'TeaserCarouselSectionRecord' }
  & Custom_TeaserCarouselSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_TeaserSectionRecord = { __typename: 'TeaserSectionRecord' };

export type PageQuery_page_PageRecord_sections_TechnicalDetailsSectionRecord = (
  { __typename: 'TechnicalDetailsSectionRecord' }
  & Custom_TechnicalDetailsSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_TextAndImageSectionRecord = (
  { __typename: 'TextAndImageSectionRecord' }
  & Custom_TextAndImageSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_TitleAndTextSectionRecord = (
  { __typename: 'TitleAndTextSectionRecord' }
  & Custom_TitleAndTextSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_UspListSectionRecord = (
  { __typename: 'UspListSectionRecord' }
  & Custom_UspListSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_UspScrollSectionRecord = (
  { __typename: 'UspScrollSectionRecord' }
  & Custom_UspScrollSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections = PageQuery_page_PageRecord_sections_AllBrandsSectionRecord | PageQuery_page_PageRecord_sections_BannerCarouselSectionRecord | PageQuery_page_PageRecord_sections_BannerSectionRecord | PageQuery_page_PageRecord_sections_CallToActionSectionRecord | PageQuery_page_PageRecord_sections_CenteredTextSectionRecord | PageQuery_page_PageRecord_sections_ContactTeasersSectionRecord | PageQuery_page_PageRecord_sections_ContentSectionRecord | PageQuery_page_PageRecord_sections_DealersSectionRecord | PageQuery_page_PageRecord_sections_FaqSectionRecord | PageQuery_page_PageRecord_sections_IconFeaturesSectionRecord | PageQuery_page_PageRecord_sections_ImageFeaturesSectionRecord | PageQuery_page_PageRecord_sections_SectionDividerRecord | PageQuery_page_PageRecord_sections_SeriesSectionRecord | PageQuery_page_PageRecord_sections_TeaserCarouselSectionRecord | PageQuery_page_PageRecord_sections_TeaserSectionRecord | PageQuery_page_PageRecord_sections_TechnicalDetailsSectionRecord | PageQuery_page_PageRecord_sections_TextAndImageSectionRecord | PageQuery_page_PageRecord_sections_TitleAndTextSectionRecord | PageQuery_page_PageRecord_sections_UspListSectionRecord | PageQuery_page_PageRecord_sections_UspScrollSectionRecord;

export type PageQuery_page_PageRecord__seoMetaTags_Tag = { __typename: 'Tag', content?: string | null, tag: string, attributes?: Record<string, string> | null };

export type PageQuery_page_PageRecord__allSlugLocales_StringNonNullMultiLocaleField = { __typename: 'StringNonNullMultiLocaleField', locale?: SiteLocale | null, value: string };

export type PageQuery_page_PageRecord = { __typename: 'PageRecord', id: string, header?: PageQuery_page_PageRecord_header_PageHeaderRecord | null, sections: Array<PageQuery_page_PageRecord_sections>, _seoMetaTags: Array<PageQuery_page_PageRecord__seoMetaTags_Tag>, _allSlugLocales?: Array<PageQuery_page_PageRecord__allSlugLocales_StringNonNullMultiLocaleField> | null };

export type PageQuery_Query = { page?: PageQuery_page_PageRecord | null };


export type PageQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<SiteLocale>;
}>;


export type PageQuery = PageQuery_Query;

export type SeriesDetailQuery_seriesPage_SeriesPageRecord_sections_BannerCarouselSectionRecord = { __typename: 'BannerCarouselSectionRecord' };

export type SeriesDetailQuery_seriesPage_SeriesPageRecord_sections_CenteredTextSectionRecord = { __typename: 'CenteredTextSectionRecord' };

export type SeriesDetailQuery_seriesPage_SeriesPageRecord_sections_FaqSectionRecord = { __typename: 'FaqSectionRecord' };

export type SeriesDetailQuery_seriesPage_SeriesPageRecord_sections_IconFeaturesSectionRecord = { __typename: 'IconFeaturesSectionRecord' };

export type SeriesDetailQuery_seriesPage_SeriesPageRecord_sections_TextAndImageSectionRecord = { __typename: 'TextAndImageSectionRecord' };

export type SeriesDetailQuery_seriesPage_SeriesPageRecord_sections = SeriesDetailQuery_seriesPage_SeriesPageRecord_sections_BannerCarouselSectionRecord | SeriesDetailQuery_seriesPage_SeriesPageRecord_sections_CenteredTextSectionRecord | SeriesDetailQuery_seriesPage_SeriesPageRecord_sections_FaqSectionRecord | SeriesDetailQuery_seriesPage_SeriesPageRecord_sections_IconFeaturesSectionRecord | SeriesDetailQuery_seriesPage_SeriesPageRecord_sections_TextAndImageSectionRecord;

export type SeriesDetailQuery_seriesPage_SeriesPageRecord__seoMetaTags_Tag = { __typename: 'Tag', content?: string | null, tag: string, attributes?: Record<string, string> | null };

export type SeriesDetailQuery_seriesPage_SeriesPageRecord__allSlugLocales_StringNonNullMultiLocaleField = { __typename: 'StringNonNullMultiLocaleField', locale?: SiteLocale | null, value: string };

export type SeriesDetailQuery_seriesPage_SeriesPageRecord = { __typename: 'SeriesPageRecord', id: string, code: string, title: string, slug: string, sections: Array<SeriesDetailQuery_seriesPage_SeriesPageRecord_sections>, _seoMetaTags: Array<SeriesDetailQuery_seriesPage_SeriesPageRecord__seoMetaTags_Tag>, _allSlugLocales?: Array<SeriesDetailQuery_seriesPage_SeriesPageRecord__allSlugLocales_StringNonNullMultiLocaleField> | null };

export type SeriesDetailQuery_Query = { seriesPage?: SeriesDetailQuery_seriesPage_SeriesPageRecord | null };


export type SeriesDetailQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<SiteLocale>;
}>;


export type SeriesDetailQuery = SeriesDetailQuery_Query;

export type SolutionsDetailQuery_solutionPage_SolutionPageRecord_sections_AllBrandsSectionRecord = { __typename: 'AllBrandsSectionRecord' };

export type SolutionsDetailQuery_solutionPage_SolutionPageRecord_sections_BannerSectionRecord = { __typename: 'BannerSectionRecord' };

export type SolutionsDetailQuery_solutionPage_SolutionPageRecord_sections_ContentSectionRecord = (
  { __typename: 'ContentSectionRecord' }
  & Custom_ContentSectionRecordFragment
);

export type SolutionsDetailQuery_solutionPage_SolutionPageRecord_sections_FaqSectionRecord = { __typename: 'FaqSectionRecord' };

export type SolutionsDetailQuery_solutionPage_SolutionPageRecord_sections_TeaserSectionRecord = { __typename: 'TeaserSectionRecord' };

export type SolutionsDetailQuery_solutionPage_SolutionPageRecord_sections_UspListSectionRecord = { __typename: 'UspListSectionRecord' };

export type SolutionsDetailQuery_solutionPage_SolutionPageRecord_sections = SolutionsDetailQuery_solutionPage_SolutionPageRecord_sections_AllBrandsSectionRecord | SolutionsDetailQuery_solutionPage_SolutionPageRecord_sections_BannerSectionRecord | SolutionsDetailQuery_solutionPage_SolutionPageRecord_sections_ContentSectionRecord | SolutionsDetailQuery_solutionPage_SolutionPageRecord_sections_FaqSectionRecord | SolutionsDetailQuery_solutionPage_SolutionPageRecord_sections_TeaserSectionRecord | SolutionsDetailQuery_solutionPage_SolutionPageRecord_sections_UspListSectionRecord;

export type SolutionsDetailQuery_solutionPage_SolutionPageRecord__seoMetaTags_Tag = { __typename: 'Tag', content?: string | null, tag: string, attributes?: Record<string, string> | null };

export type SolutionsDetailQuery_solutionPage_SolutionPageRecord__allSlugLocales_StringNonNullMultiLocaleField = { __typename: 'StringNonNullMultiLocaleField', locale?: SiteLocale | null, value: string };

export type SolutionsDetailQuery_solutionPage_SolutionPageRecord = { __typename: 'SolutionPageRecord', id: string, code: string, title: string, slug: string, sections: Array<SolutionsDetailQuery_solutionPage_SolutionPageRecord_sections>, _seoMetaTags: Array<SolutionsDetailQuery_solutionPage_SolutionPageRecord__seoMetaTags_Tag>, _allSlugLocales?: Array<SolutionsDetailQuery_solutionPage_SolutionPageRecord__allSlugLocales_StringNonNullMultiLocaleField> | null };

export type SolutionsDetailQuery_Query = { solutionPage?: SolutionsDetailQuery_solutionPage_SolutionPageRecord | null };


export type SolutionsDetailQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<SiteLocale>;
}>;


export type SolutionsDetailQuery = SolutionsDetailQuery_Query;

export type Custom_BannerCarouselSectionRecordFragment_BannerCarouselSectionRecord_banners_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_BannerCarouselSectionRecordFragment_BannerCarouselSectionRecord_banners_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_BannerCarouselSectionRecordFragment_BannerCarouselSectionRecord_banners_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_BannerCarouselSectionRecordFragment = { __typename: 'BannerCarouselSectionRecord', id: string, sectionTitle?: string | null, banners: Array<Custom_BannerCarouselSectionRecordFragment_BannerCarouselSectionRecord_banners_ImageFileField> };

export type Custom_CallToActionRecordFragment_CallToActionRecord_content_CallToActionModelContentField = { __typename: 'CallToActionModelContentField', value: unknown };

export type Custom_CallToActionRecordFragment_CallToActionRecord_actionLink_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type Custom_CallToActionRecordFragment_CallToActionRecord_actionLink_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type Custom_CallToActionRecordFragment_CallToActionRecord_actionLink = Custom_CallToActionRecordFragment_CallToActionRecord_actionLink_CustomLinkRecord | Custom_CallToActionRecordFragment_CallToActionRecord_actionLink_PageLinkRecord;

export type Custom_CallToActionRecordFragment_CallToActionRecord_image_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_CallToActionRecordFragment_CallToActionRecord_image_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_CallToActionRecordFragment_CallToActionRecord_image_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_CallToActionRecordFragment = { __typename: 'CallToActionRecord', content: Custom_CallToActionRecordFragment_CallToActionRecord_content_CallToActionModelContentField, actionLink: Custom_CallToActionRecordFragment_CallToActionRecord_actionLink, image: Custom_CallToActionRecordFragment_CallToActionRecord_image_ImageFileField };

export type Custom_CallToActionSectionRecordFragment_CallToActionSectionRecord_callToAction_CallToActionRecord = (
  { __typename: 'CallToActionRecord' }
  & Custom_CallToActionRecordFragment
);

export type Custom_CallToActionSectionRecordFragment = { __typename: 'CallToActionSectionRecord', id: string, sectionTitle?: string | null, callToAction: Custom_CallToActionSectionRecordFragment_CallToActionSectionRecord_callToAction_CallToActionRecord };

export type Custom_CategoryPageRecordForLinkFragment = { __typename: 'CategoryPageRecord', _modelApiKey: string, id: string, title: string, slug: string };

export type Custom_CenteredTextSectionRecordFragment = { __typename: 'CenteredTextSectionRecord', id: string, sectionTitle?: string | null, title: string, text: string };

export type Custom_ContactTeasersSectionRecordFragment_ContactTeasersSectionRecord_teasers_TeaserRecord_image_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_ContactTeasersSectionRecordFragment_ContactTeasersSectionRecord_teasers_TeaserRecord_image_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_ContactTeasersSectionRecordFragment_ContactTeasersSectionRecord_teasers_TeaserRecord_image_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_ContactTeasersSectionRecordFragment_ContactTeasersSectionRecord_teasers_TeaserRecord_link_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type Custom_ContactTeasersSectionRecordFragment_ContactTeasersSectionRecord_teasers_TeaserRecord_link_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type Custom_ContactTeasersSectionRecordFragment_ContactTeasersSectionRecord_teasers_TeaserRecord_link = Custom_ContactTeasersSectionRecordFragment_ContactTeasersSectionRecord_teasers_TeaserRecord_link_CustomLinkRecord | Custom_ContactTeasersSectionRecordFragment_ContactTeasersSectionRecord_teasers_TeaserRecord_link_PageLinkRecord;

export type Custom_ContactTeasersSectionRecordFragment_ContactTeasersSectionRecord_teasers_TeaserRecord = { __typename: 'TeaserRecord', id: string, title: string, optText?: string | null, image: Custom_ContactTeasersSectionRecordFragment_ContactTeasersSectionRecord_teasers_TeaserRecord_image_ImageFileField, link: Custom_ContactTeasersSectionRecordFragment_ContactTeasersSectionRecord_teasers_TeaserRecord_link };

export type Custom_ContactTeasersSectionRecordFragment = { __typename: 'ContactTeasersSectionRecord', sectionTitle?: string | null, teasers: Array<Custom_ContactTeasersSectionRecordFragment_ContactTeasersSectionRecord_teasers_TeaserRecord> };

export type Custom_ContentSectionRecordFragment_ContentSectionRecord_body_ContentSectionModelBodyField_blocks_ButtonRecord = { __typename: 'ButtonRecord' };

export type Custom_ContentSectionRecordFragment_ContentSectionRecord_body_ContentSectionModelBodyField_blocks_ImageBlockRecord = (
  { __typename: 'ImageBlockRecord' }
  & Custom_ImageBlockRecordFragment
);

export type Custom_ContentSectionRecordFragment_ContentSectionRecord_body_ContentSectionModelBodyField_blocks_SplitItemRecord = { __typename: 'SplitItemRecord' };

export type Custom_ContentSectionRecordFragment_ContentSectionRecord_body_ContentSectionModelBodyField_blocks = Custom_ContentSectionRecordFragment_ContentSectionRecord_body_ContentSectionModelBodyField_blocks_ButtonRecord | Custom_ContentSectionRecordFragment_ContentSectionRecord_body_ContentSectionModelBodyField_blocks_ImageBlockRecord | Custom_ContentSectionRecordFragment_ContentSectionRecord_body_ContentSectionModelBodyField_blocks_SplitItemRecord;

export type Custom_ContentSectionRecordFragment_ContentSectionRecord_body_ContentSectionModelBodyField = { __typename: 'ContentSectionModelBodyField', links: Array<string>, value: unknown, blocks: Array<Custom_ContentSectionRecordFragment_ContentSectionRecord_body_ContentSectionModelBodyField_blocks> };

export type Custom_ContentSectionRecordFragment = { __typename: 'ContentSectionRecord', id: string, body: Custom_ContentSectionRecordFragment_ContentSectionRecord_body_ContentSectionModelBodyField };

export type Custom_CustomLinkRecordFragment = { __typename: 'CustomLinkRecord', _modelApiKey: string, id: string, text: string, optLinkUrl?: string | null };

export type Custom_DealerRecordFragment = { __typename: 'DealerRecord', _modelApiKey: string, id: string, name: string, address: string, phoneNumber: string, eMailAddress: string };

export type Custom_DealersSectionRecordFragment_DealersSectionRecord_mapImage_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_DealersSectionRecordFragment_DealersSectionRecord_mapImage_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_DealersSectionRecordFragment_DealersSectionRecord_mapImage_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_DealersSectionRecordFragment_DealersSectionRecord_dealers_DealerRecord = (
  { __typename: 'DealerRecord' }
  & Custom_DealerRecordFragment
);

export type Custom_DealersSectionRecordFragment = { __typename: 'DealersSectionRecord', id: string, sectionTitle?: string | null, mapImage: Custom_DealersSectionRecordFragment_DealersSectionRecord_mapImage_ImageFileField, dealers: Array<Custom_DealersSectionRecordFragment_DealersSectionRecord_dealers_DealerRecord> };

export type Custom_IconFeaturesSectionRecordFragment_IconFeaturesSectionRecord_features_IconFeatureRecord_icon_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_IconFeaturesSectionRecordFragment_IconFeaturesSectionRecord_features_IconFeatureRecord_icon_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_IconFeaturesSectionRecordFragment_IconFeaturesSectionRecord_features_IconFeatureRecord_icon_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_IconFeaturesSectionRecordFragment_IconFeaturesSectionRecord_features_IconFeatureRecord = { __typename: 'IconFeatureRecord', id: string, title: string, optText?: string | null, icon: Custom_IconFeaturesSectionRecordFragment_IconFeaturesSectionRecord_features_IconFeatureRecord_icon_ImageFileField };

export type Custom_IconFeaturesSectionRecordFragment = { __typename: 'IconFeaturesSectionRecord', sectionTitle?: string | null, colorMode: boolean, features: Array<Custom_IconFeaturesSectionRecordFragment_IconFeaturesSectionRecord_features_IconFeatureRecord> };

export type Custom_IconLinkRecordFragment_IconLinkRecord_linkItem_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type Custom_IconLinkRecordFragment_IconLinkRecord_linkItem_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type Custom_IconLinkRecordFragment_IconLinkRecord_linkItem = Custom_IconLinkRecordFragment_IconLinkRecord_linkItem_CustomLinkRecord | Custom_IconLinkRecordFragment_IconLinkRecord_linkItem_PageLinkRecord;

export type Custom_IconLinkRecordFragment = { __typename: 'IconLinkRecord', _modelApiKey: string, id: string, iconName: string, linkItem: Custom_IconLinkRecordFragment_IconLinkRecord_linkItem };

export type Custom_ImageBlockRecordFragment_ImageBlockRecord_image_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_ImageBlockRecordFragment_ImageBlockRecord_image_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_ImageBlockRecordFragment_ImageBlockRecord_image_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_ImageBlockRecordFragment = { __typename: 'ImageBlockRecord', _modelApiKey: string, id: string, image: Custom_ImageBlockRecordFragment_ImageBlockRecord_image_ImageFileField };

export type Custom_ImageFeaturesSectionRecordFragment_ImageFeaturesSectionRecord_features_ImageFeatureRecord_image_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_ImageFeaturesSectionRecordFragment_ImageFeaturesSectionRecord_features_ImageFeatureRecord_image_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_ImageFeaturesSectionRecordFragment_ImageFeaturesSectionRecord_features_ImageFeatureRecord_image_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_ImageFeaturesSectionRecordFragment_ImageFeaturesSectionRecord_features_ImageFeatureRecord = { __typename: 'ImageFeatureRecord', id: string, title: string, optText?: string | null, image: Custom_ImageFeaturesSectionRecordFragment_ImageFeaturesSectionRecord_features_ImageFeatureRecord_image_ImageFileField };

export type Custom_ImageFeaturesSectionRecordFragment = { __typename: 'ImageFeaturesSectionRecord', sectionTitle?: string | null, colorMode: boolean, features: Array<Custom_ImageFeaturesSectionRecordFragment_ImageFeaturesSectionRecord_features_ImageFeatureRecord> };

export type Custom_ImageLinkRecordFragment_ImageLinkRecord_image_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_ImageLinkRecordFragment_ImageLinkRecord_image_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_ImageLinkRecordFragment_ImageLinkRecord_image_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_ImageLinkRecordFragment_ImageLinkRecord_linkItem_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type Custom_ImageLinkRecordFragment_ImageLinkRecord_linkItem_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type Custom_ImageLinkRecordFragment_ImageLinkRecord_linkItem = Custom_ImageLinkRecordFragment_ImageLinkRecord_linkItem_CustomLinkRecord | Custom_ImageLinkRecordFragment_ImageLinkRecord_linkItem_PageLinkRecord;

export type Custom_ImageLinkRecordFragment = { __typename: 'ImageLinkRecord', _modelApiKey: string, id: string, image: Custom_ImageLinkRecordFragment_ImageLinkRecord_image_ImageFileField, linkItem: Custom_ImageLinkRecordFragment_ImageLinkRecord_linkItem };

export type Custom_MenuLinkRecordFragment_MenuLinkRecord_linkItem_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type Custom_MenuLinkRecordFragment_MenuLinkRecord_linkItem_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type Custom_MenuLinkRecordFragment_MenuLinkRecord_linkItem = Custom_MenuLinkRecordFragment_MenuLinkRecord_linkItem_CustomLinkRecord | Custom_MenuLinkRecordFragment_MenuLinkRecord_linkItem_PageLinkRecord;

export type Custom_MenuLinkRecordFragment_MenuLinkRecord_subLinkItems_MenuLinkRecord_linkItem_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type Custom_MenuLinkRecordFragment_MenuLinkRecord_subLinkItems_MenuLinkRecord_linkItem_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type Custom_MenuLinkRecordFragment_MenuLinkRecord_subLinkItems_MenuLinkRecord_linkItem = Custom_MenuLinkRecordFragment_MenuLinkRecord_subLinkItems_MenuLinkRecord_linkItem_CustomLinkRecord | Custom_MenuLinkRecordFragment_MenuLinkRecord_subLinkItems_MenuLinkRecord_linkItem_PageLinkRecord;

export type Custom_MenuLinkRecordFragment_MenuLinkRecord_subLinkItems_MenuLinkRecord_subLinkItems_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type Custom_MenuLinkRecordFragment_MenuLinkRecord_subLinkItems_MenuLinkRecord_subLinkItems_MenuLinkRecord = { __typename: 'MenuLinkRecord' };

export type Custom_MenuLinkRecordFragment_MenuLinkRecord_subLinkItems_MenuLinkRecord_subLinkItems_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type Custom_MenuLinkRecordFragment_MenuLinkRecord_subLinkItems_MenuLinkRecord_subLinkItems = Custom_MenuLinkRecordFragment_MenuLinkRecord_subLinkItems_MenuLinkRecord_subLinkItems_CustomLinkRecord | Custom_MenuLinkRecordFragment_MenuLinkRecord_subLinkItems_MenuLinkRecord_subLinkItems_MenuLinkRecord | Custom_MenuLinkRecordFragment_MenuLinkRecord_subLinkItems_MenuLinkRecord_subLinkItems_PageLinkRecord;

export type Custom_MenuLinkRecordFragment_MenuLinkRecord_subLinkItems_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type Custom_MenuLinkRecordFragment_MenuLinkRecord_subLinkItems_MenuLinkRecord = { __typename: 'MenuLinkRecord', _modelApiKey: string, id: string, linkItem: Custom_MenuLinkRecordFragment_MenuLinkRecord_subLinkItems_MenuLinkRecord_linkItem, subLinkItems: Array<Custom_MenuLinkRecordFragment_MenuLinkRecord_subLinkItems_MenuLinkRecord_subLinkItems> };

export type Custom_MenuLinkRecordFragment_MenuLinkRecord_subLinkItems_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type Custom_MenuLinkRecordFragment_MenuLinkRecord_subLinkItems = Custom_MenuLinkRecordFragment_MenuLinkRecord_subLinkItems_CustomLinkRecord | Custom_MenuLinkRecordFragment_MenuLinkRecord_subLinkItems_MenuLinkRecord | Custom_MenuLinkRecordFragment_MenuLinkRecord_subLinkItems_PageLinkRecord;

export type Custom_MenuLinkRecordFragment = { __typename: 'MenuLinkRecord', _modelApiKey: string, id: string, linkItem: Custom_MenuLinkRecordFragment_MenuLinkRecord_linkItem, subLinkItems: Array<Custom_MenuLinkRecordFragment_MenuLinkRecord_subLinkItems> };

export type Custom_PageHeaderRecordFragment_PageHeaderRecord_optImage_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_PageHeaderRecordFragment_PageHeaderRecord_optImage_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_PageHeaderRecordFragment_PageHeaderRecord_optImage_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_PageHeaderRecordFragment_PageHeaderRecord_ctaList_ButtonRecord_linkItem_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type Custom_PageHeaderRecordFragment_PageHeaderRecord_ctaList_ButtonRecord_linkItem_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type Custom_PageHeaderRecordFragment_PageHeaderRecord_ctaList_ButtonRecord_linkItem = Custom_PageHeaderRecordFragment_PageHeaderRecord_ctaList_ButtonRecord_linkItem_CustomLinkRecord | Custom_PageHeaderRecordFragment_PageHeaderRecord_ctaList_ButtonRecord_linkItem_PageLinkRecord;

export type Custom_PageHeaderRecordFragment_PageHeaderRecord_ctaList_ButtonRecord = { __typename: 'ButtonRecord', primary: boolean, linkItem: Custom_PageHeaderRecordFragment_PageHeaderRecord_ctaList_ButtonRecord_linkItem };

export type Custom_PageHeaderRecordFragment = { __typename: 'PageHeaderRecord', title: string, optIntro?: string | null, colorMode: boolean, optImage?: Custom_PageHeaderRecordFragment_PageHeaderRecord_optImage_ImageFileField | null, ctaList: Array<Custom_PageHeaderRecordFragment_PageHeaderRecord_ctaList_ButtonRecord> };

export type Custom_PageLinkRecordFragment_PageLinkRecord_link_CategoryPageRecord = (
  { __typename: 'CategoryPageRecord' }
  & Custom_CategoryPageRecordForLinkFragment
);

export type Custom_PageLinkRecordFragment_PageLinkRecord_link_PageRecord = (
  { __typename: 'PageRecord' }
  & Custom_PageRecordForLinkFragment
);

export type Custom_PageLinkRecordFragment_PageLinkRecord_link_SeriesPageRecord = (
  { __typename: 'SeriesPageRecord' }
  & Custom_SeriesPageRecordForLinkFragment
);

export type Custom_PageLinkRecordFragment_PageLinkRecord_link_SolutionPageRecord = (
  { __typename: 'SolutionPageRecord' }
  & Custom_SolutionPageRecordForLinkFragment
);

export type Custom_PageLinkRecordFragment_PageLinkRecord_link = Custom_PageLinkRecordFragment_PageLinkRecord_link_CategoryPageRecord | Custom_PageLinkRecordFragment_PageLinkRecord_link_PageRecord | Custom_PageLinkRecordFragment_PageLinkRecord_link_SeriesPageRecord | Custom_PageLinkRecordFragment_PageLinkRecord_link_SolutionPageRecord;

export type Custom_PageLinkRecordFragment = { __typename: 'PageLinkRecord', _modelApiKey: string, id: string, text: string, link: Custom_PageLinkRecordFragment_PageLinkRecord_link };

export type Custom_PageRecordForLinkFragment = { __typename: 'PageRecord', _modelApiKey: string, id: string, title: string, slug: string };

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_mainLink_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_mainLink_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_mainLink = Custom_PanelLinkRecordFragment_PanelLinkRecord_mainLink_CustomLinkRecord | Custom_PanelLinkRecordFragment_PanelLinkRecord_mainLink_PageLinkRecord;

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn1_MenuLinkRecord_linkItem_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn1_MenuLinkRecord_linkItem_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn1_MenuLinkRecord_linkItem = Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn1_MenuLinkRecord_linkItem_CustomLinkRecord | Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn1_MenuLinkRecord_linkItem_PageLinkRecord;

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn1_MenuLinkRecord_subLinkItems_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn1_MenuLinkRecord_subLinkItems_MenuLinkRecord = { __typename: 'MenuLinkRecord' };

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn1_MenuLinkRecord_subLinkItems_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn1_MenuLinkRecord_subLinkItems = Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn1_MenuLinkRecord_subLinkItems_CustomLinkRecord | Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn1_MenuLinkRecord_subLinkItems_MenuLinkRecord | Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn1_MenuLinkRecord_subLinkItems_PageLinkRecord;

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn1_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn1_MenuLinkRecord = { __typename: 'MenuLinkRecord', _modelApiKey: string, id: string, linkItem: Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn1_MenuLinkRecord_linkItem, subLinkItems: Array<Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn1_MenuLinkRecord_subLinkItems> };

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn1_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn1 = Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn1_CustomLinkRecord | Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn1_MenuLinkRecord | Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn1_PageLinkRecord;

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn2_MenuLinkRecord_linkItem_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn2_MenuLinkRecord_linkItem_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn2_MenuLinkRecord_linkItem = Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn2_MenuLinkRecord_linkItem_CustomLinkRecord | Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn2_MenuLinkRecord_linkItem_PageLinkRecord;

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn2_MenuLinkRecord_subLinkItems_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn2_MenuLinkRecord_subLinkItems_MenuLinkRecord = { __typename: 'MenuLinkRecord' };

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn2_MenuLinkRecord_subLinkItems_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn2_MenuLinkRecord_subLinkItems = Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn2_MenuLinkRecord_subLinkItems_CustomLinkRecord | Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn2_MenuLinkRecord_subLinkItems_MenuLinkRecord | Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn2_MenuLinkRecord_subLinkItems_PageLinkRecord;

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn2_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn2_MenuLinkRecord = { __typename: 'MenuLinkRecord', _modelApiKey: string, id: string, linkItem: Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn2_MenuLinkRecord_linkItem, subLinkItems: Array<Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn2_MenuLinkRecord_subLinkItems> };

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn2_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn2 = Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn2_CustomLinkRecord | Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn2_MenuLinkRecord | Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn2_PageLinkRecord;

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn3_MenuLinkRecord_linkItem_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn3_MenuLinkRecord_linkItem_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn3_MenuLinkRecord_linkItem = Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn3_MenuLinkRecord_linkItem_CustomLinkRecord | Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn3_MenuLinkRecord_linkItem_PageLinkRecord;

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn3_MenuLinkRecord_subLinkItems_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn3_MenuLinkRecord_subLinkItems_MenuLinkRecord = { __typename: 'MenuLinkRecord' };

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn3_MenuLinkRecord_subLinkItems_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn3_MenuLinkRecord_subLinkItems = Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn3_MenuLinkRecord_subLinkItems_CustomLinkRecord | Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn3_MenuLinkRecord_subLinkItems_MenuLinkRecord | Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn3_MenuLinkRecord_subLinkItems_PageLinkRecord;

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn3_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn3_MenuLinkRecord = { __typename: 'MenuLinkRecord', _modelApiKey: string, id: string, linkItem: Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn3_MenuLinkRecord_linkItem, subLinkItems: Array<Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn3_MenuLinkRecord_subLinkItems> };

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn3_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn3 = Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn3_CustomLinkRecord | Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn3_MenuLinkRecord | Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn3_PageLinkRecord;

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_callToAction_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_callToAction_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_callToAction = Custom_PanelLinkRecordFragment_PanelLinkRecord_callToAction_CustomLinkRecord | Custom_PanelLinkRecordFragment_PanelLinkRecord_callToAction_PageLinkRecord;

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_sidePanel_SidePanelRecord_image_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_sidePanel_SidePanelRecord_image_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_PanelLinkRecordFragment_PanelLinkRecord_sidePanel_SidePanelRecord_image_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_sidePanel_SidePanelRecord_link_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_sidePanel_SidePanelRecord_link_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_sidePanel_SidePanelRecord_link = Custom_PanelLinkRecordFragment_PanelLinkRecord_sidePanel_SidePanelRecord_link_CustomLinkRecord | Custom_PanelLinkRecordFragment_PanelLinkRecord_sidePanel_SidePanelRecord_link_PageLinkRecord;

export type Custom_PanelLinkRecordFragment_PanelLinkRecord_sidePanel_SidePanelRecord = { __typename: 'SidePanelRecord', title: string, image: Custom_PanelLinkRecordFragment_PanelLinkRecord_sidePanel_SidePanelRecord_image_ImageFileField, link: Custom_PanelLinkRecordFragment_PanelLinkRecord_sidePanel_SidePanelRecord_link };

export type Custom_PanelLinkRecordFragment = { __typename: 'PanelLinkRecord', _modelApiKey: string, id: string, mainLink: Custom_PanelLinkRecordFragment_PanelLinkRecord_mainLink, subLinksColumn1: Array<Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn1>, subLinksColumn2: Array<Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn2>, subLinksColumn3: Array<Custom_PanelLinkRecordFragment_PanelLinkRecord_subLinksColumn3>, callToAction?: Custom_PanelLinkRecordFragment_PanelLinkRecord_callToAction | null, sidePanel?: Custom_PanelLinkRecordFragment_PanelLinkRecord_sidePanel_SidePanelRecord | null };

export type Custom_SectionDividerRecordFragment = { __typename: 'SectionDividerRecord', id: string };

export type Custom_SeriesPageRecordForLinkFragment = { __typename: 'SeriesPageRecord', _modelApiKey: string, id: string, title: string, slug: string };

export type Custom_SolutionPageRecordForLinkFragment = { __typename: 'SolutionPageRecord', _modelApiKey: string, id: string, title: string, slug: string };

export type Custom_TeaserCarouselSectionRecordFragment_TeaserCarouselSectionRecord_teasers_TeaserRecord = (
  { __typename: 'TeaserRecord' }
  & Custom_TeaserRecordFragment
);

export type Custom_TeaserCarouselSectionRecordFragment = { __typename: 'TeaserCarouselSectionRecord', id: string, sectionTitle?: string | null, slidesPerView?: number | null, variant: boolean, teasers: Array<Custom_TeaserCarouselSectionRecordFragment_TeaserCarouselSectionRecord_teasers_TeaserRecord> };

export type Custom_TeaserRecordFragment_TeaserRecord_image_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_TeaserRecordFragment_TeaserRecord_image_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_TeaserRecordFragment_TeaserRecord_image_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_TeaserRecordFragment_TeaserRecord_link_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type Custom_TeaserRecordFragment_TeaserRecord_link_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type Custom_TeaserRecordFragment_TeaserRecord_link = Custom_TeaserRecordFragment_TeaserRecord_link_CustomLinkRecord | Custom_TeaserRecordFragment_TeaserRecord_link_PageLinkRecord;

export type Custom_TeaserRecordFragment = { __typename: 'TeaserRecord', id: string, title: string, optText?: string | null, image: Custom_TeaserRecordFragment_TeaserRecord_image_ImageFileField, link: Custom_TeaserRecordFragment_TeaserRecord_link };

export type Custom_TechnicalDetailRecordFragment = { __typename: 'TechnicalDetailRecord', _modelApiKey: string, id: string, title: string, info: string };

export type Custom_TechnicalDetailsSectionRecordFragment_TechnicalDetailsSectionRecord_technicalDetails_TechnicalDetailRecord = (
  { __typename: 'TechnicalDetailRecord' }
  & Custom_TechnicalDetailRecordFragment
);

export type Custom_TechnicalDetailsSectionRecordFragment_TechnicalDetailsSectionRecord_image_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_TechnicalDetailsSectionRecordFragment_TechnicalDetailsSectionRecord_image_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_TechnicalDetailsSectionRecordFragment_TechnicalDetailsSectionRecord_image_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_TechnicalDetailsSectionRecordFragment_TechnicalDetailsSectionRecord_downloadLink_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type Custom_TechnicalDetailsSectionRecordFragment_TechnicalDetailsSectionRecord_downloadLink_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type Custom_TechnicalDetailsSectionRecordFragment_TechnicalDetailsSectionRecord_downloadLink = Custom_TechnicalDetailsSectionRecordFragment_TechnicalDetailsSectionRecord_downloadLink_CustomLinkRecord | Custom_TechnicalDetailsSectionRecordFragment_TechnicalDetailsSectionRecord_downloadLink_PageLinkRecord;

export type Custom_TechnicalDetailsSectionRecordFragment = { __typename: 'TechnicalDetailsSectionRecord', id: string, sectionTitle?: string | null, title: string, technicalDetails: Array<Custom_TechnicalDetailsSectionRecordFragment_TechnicalDetailsSectionRecord_technicalDetails_TechnicalDetailRecord>, image: Custom_TechnicalDetailsSectionRecordFragment_TechnicalDetailsSectionRecord_image_ImageFileField, downloadLink: Custom_TechnicalDetailsSectionRecordFragment_TechnicalDetailsSectionRecord_downloadLink };

export type Custom_TextAndImageSectionRecordFragment_TextAndImageSectionRecord_titleAndText_TitleAndTextRecord_link_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type Custom_TextAndImageSectionRecordFragment_TextAndImageSectionRecord_titleAndText_TitleAndTextRecord_link_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type Custom_TextAndImageSectionRecordFragment_TextAndImageSectionRecord_titleAndText_TitleAndTextRecord_link = Custom_TextAndImageSectionRecordFragment_TextAndImageSectionRecord_titleAndText_TitleAndTextRecord_link_CustomLinkRecord | Custom_TextAndImageSectionRecordFragment_TextAndImageSectionRecord_titleAndText_TitleAndTextRecord_link_PageLinkRecord;

export type Custom_TextAndImageSectionRecordFragment_TextAndImageSectionRecord_titleAndText_TitleAndTextRecord = { __typename: 'TitleAndTextRecord', title: string, text: string, linkVariant: boolean, link?: Custom_TextAndImageSectionRecordFragment_TextAndImageSectionRecord_titleAndText_TitleAndTextRecord_link | null };

export type Custom_TextAndImageSectionRecordFragment_TextAndImageSectionRecord_image_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_TextAndImageSectionRecordFragment_TextAndImageSectionRecord_image_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_TextAndImageSectionRecordFragment_TextAndImageSectionRecord_image_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_TextAndImageSectionRecordFragment = { __typename: 'TextAndImageSectionRecord', id: string, sectionTitle?: string | null, imageSide: boolean, titleAndText: Custom_TextAndImageSectionRecordFragment_TextAndImageSectionRecord_titleAndText_TitleAndTextRecord, image: Custom_TextAndImageSectionRecordFragment_TextAndImageSectionRecord_image_ImageFileField };

export type Custom_TitleAndTextSectionRecordFragment_TitleAndTextSectionRecord_titleAndText_TitleAndTextRecord_link_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type Custom_TitleAndTextSectionRecordFragment_TitleAndTextSectionRecord_titleAndText_TitleAndTextRecord_link_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type Custom_TitleAndTextSectionRecordFragment_TitleAndTextSectionRecord_titleAndText_TitleAndTextRecord_link = Custom_TitleAndTextSectionRecordFragment_TitleAndTextSectionRecord_titleAndText_TitleAndTextRecord_link_CustomLinkRecord | Custom_TitleAndTextSectionRecordFragment_TitleAndTextSectionRecord_titleAndText_TitleAndTextRecord_link_PageLinkRecord;

export type Custom_TitleAndTextSectionRecordFragment_TitleAndTextSectionRecord_titleAndText_TitleAndTextRecord = { __typename: 'TitleAndTextRecord', title: string, text: string, linkVariant: boolean, link?: Custom_TitleAndTextSectionRecordFragment_TitleAndTextSectionRecord_titleAndText_TitleAndTextRecord_link | null };

export type Custom_TitleAndTextSectionRecordFragment = { __typename: 'TitleAndTextSectionRecord', id: string, sectionTitle?: string | null, colorMode: boolean, extraMargin: boolean, titleAndText: Custom_TitleAndTextSectionRecordFragment_TitleAndTextSectionRecord_titleAndText_TitleAndTextRecord };

export type Custom_UspListSectionRecordFragment_UspListSectionRecord_sellingPoints_UspRecord = (
  { __typename: 'UspRecord' }
  & Custom_UspRecordFragment
);

export type Custom_UspListSectionRecordFragment = { __typename: 'UspListSectionRecord', id: string, sectionTitle?: string | null, colorMode: boolean, sellingPoints: Array<Custom_UspListSectionRecordFragment_UspListSectionRecord_sellingPoints_UspRecord> };

export type Custom_UspRecordFragment_UspRecord_image_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_UspRecordFragment_UspRecord_image_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_UspRecordFragment_UspRecord_image_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_UspRecordFragment = { __typename: 'UspRecord', _modelApiKey: string, id: string, title: string, text: string, image?: Custom_UspRecordFragment_UspRecord_image_ImageFileField | null };

export type Custom_UspScrollSectionRecordFragment_UspScrollSectionRecord_sellingPoints_UspRecord = (
  { __typename: 'UspRecord' }
  & Custom_UspRecordFragment
);

export type Custom_UspScrollSectionRecordFragment_UspScrollSectionRecord_callToActionLink_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type Custom_UspScrollSectionRecordFragment_UspScrollSectionRecord_callToActionLink_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type Custom_UspScrollSectionRecordFragment_UspScrollSectionRecord_callToActionLink = Custom_UspScrollSectionRecordFragment_UspScrollSectionRecord_callToActionLink_CustomLinkRecord | Custom_UspScrollSectionRecordFragment_UspScrollSectionRecord_callToActionLink_PageLinkRecord;

export type Custom_UspScrollSectionRecordFragment = { __typename: 'UspScrollSectionRecord', id: string, sectionTitle?: string | null, title: string, callToActionText: string, sellingPoints: Array<Custom_UspScrollSectionRecordFragment_UspScrollSectionRecord_sellingPoints_UspRecord>, callToActionLink: Custom_UspScrollSectionRecordFragment_UspScrollSectionRecord_callToActionLink };

export type DatoImage_ResponsiveImageFragment = { __typename: 'ResponsiveImage', width: number, height: number, src: string, srcSet: string, webpSrcSet: string, alt?: string | null, title?: string | null, sizes: string, base64?: string | null, aspectRatio: number };

export const DatoImage_ResponsiveImageFragmentDoc = gql`
    fragment DatoImage_ResponsiveImage on ResponsiveImage {
  width
  height
  src
  srcSet
  webpSrcSet
  alt
  title
  sizes
  base64
  aspectRatio
}
    `;
export const Custom_BannerCarouselSectionRecordFragmentDoc = gql`
    fragment Custom_BannerCarouselSectionRecord on BannerCarouselSectionRecord {
  id
  sectionTitle
  banners {
    responsiveImage {
      ...DatoImage_ResponsiveImage
    }
  }
}
    ${DatoImage_ResponsiveImageFragmentDoc}`;
export const Custom_CustomLinkRecordFragmentDoc = gql`
    fragment Custom_CustomLinkRecord on CustomLinkRecord {
  _modelApiKey
  id
  text
  optLinkUrl
}
    `;
export const Custom_PageRecordForLinkFragmentDoc = gql`
    fragment Custom_PageRecordForLink on PageRecord {
  _modelApiKey
  id
  title
  slug
}
    `;
export const Custom_CategoryPageRecordForLinkFragmentDoc = gql`
    fragment Custom_CategoryPageRecordForLink on CategoryPageRecord {
  _modelApiKey
  id
  title
  slug
}
    `;
export const Custom_SeriesPageRecordForLinkFragmentDoc = gql`
    fragment Custom_SeriesPageRecordForLink on SeriesPageRecord {
  _modelApiKey
  id
  title
  slug
}
    `;
export const Custom_SolutionPageRecordForLinkFragmentDoc = gql`
    fragment Custom_SolutionPageRecordForLink on SolutionPageRecord {
  _modelApiKey
  id
  title
  slug
}
    `;
export const Custom_PageLinkRecordFragmentDoc = gql`
    fragment Custom_PageLinkRecord on PageLinkRecord {
  _modelApiKey
  id
  text
  link {
    __typename
    ...Custom_PageRecordForLink
    ...Custom_CategoryPageRecordForLink
    ...Custom_SeriesPageRecordForLink
    ...Custom_SolutionPageRecordForLink
  }
}
    ${Custom_PageRecordForLinkFragmentDoc}
${Custom_CategoryPageRecordForLinkFragmentDoc}
${Custom_SeriesPageRecordForLinkFragmentDoc}
${Custom_SolutionPageRecordForLinkFragmentDoc}`;
export const Custom_CallToActionRecordFragmentDoc = gql`
    fragment Custom_CallToActionRecord on CallToActionRecord {
  content {
    value
  }
  actionLink {
    ...Custom_CustomLinkRecord
    ...Custom_PageLinkRecord
  }
  image {
    responsiveImage(imgixParams: {w: 1000, fit: max, auto: [compress, format]}) {
      ...DatoImage_ResponsiveImage
    }
  }
}
    ${Custom_CustomLinkRecordFragmentDoc}
${Custom_PageLinkRecordFragmentDoc}
${DatoImage_ResponsiveImageFragmentDoc}`;
export const Custom_CallToActionSectionRecordFragmentDoc = gql`
    fragment Custom_CallToActionSectionRecord on CallToActionSectionRecord {
  id
  sectionTitle
  callToAction {
    ...Custom_CallToActionRecord
  }
}
    ${Custom_CallToActionRecordFragmentDoc}`;
export const Custom_CenteredTextSectionRecordFragmentDoc = gql`
    fragment Custom_CenteredTextSectionRecord on CenteredTextSectionRecord {
  id
  sectionTitle
  title
  text
}
    `;
export const Custom_ContactTeasersSectionRecordFragmentDoc = gql`
    fragment Custom_ContactTeasersSectionRecord on ContactTeasersSectionRecord {
  sectionTitle
  teasers {
    id
    image {
      responsiveImage(imgixParams: {w: 800, fit: max, auto: [compress, format]}) {
        ...DatoImage_ResponsiveImage
      }
    }
    title
    optText
    link {
      ...Custom_CustomLinkRecord
      ...Custom_PageLinkRecord
    }
  }
}
    ${DatoImage_ResponsiveImageFragmentDoc}
${Custom_CustomLinkRecordFragmentDoc}
${Custom_PageLinkRecordFragmentDoc}`;
export const Custom_ImageBlockRecordFragmentDoc = gql`
    fragment Custom_ImageBlockRecord on ImageBlockRecord {
  _modelApiKey
  id
  image {
    responsiveImage(imgixParams: {w: "1500", auto: [compress]}) {
      ...DatoImage_ResponsiveImage
    }
  }
}
    ${DatoImage_ResponsiveImageFragmentDoc}`;
export const Custom_ContentSectionRecordFragmentDoc = gql`
    fragment Custom_ContentSectionRecord on ContentSectionRecord {
  id
  body {
    blocks {
      ...Custom_ImageBlockRecord
    }
    links
    value
  }
}
    ${Custom_ImageBlockRecordFragmentDoc}`;
export const Custom_DealerRecordFragmentDoc = gql`
    fragment Custom_DealerRecord on DealerRecord {
  _modelApiKey
  id
  name
  address
  phoneNumber
  eMailAddress
}
    `;
export const Custom_DealersSectionRecordFragmentDoc = gql`
    fragment Custom_DealersSectionRecord on DealersSectionRecord {
  id
  sectionTitle
  mapImage {
    responsiveImage(imgixParams: {w: "1120", auto: [compress, format]}) {
      ...DatoImage_ResponsiveImage
    }
  }
  dealers {
    ...Custom_DealerRecord
  }
}
    ${DatoImage_ResponsiveImageFragmentDoc}
${Custom_DealerRecordFragmentDoc}`;
export const Custom_IconFeaturesSectionRecordFragmentDoc = gql`
    fragment Custom_IconFeaturesSectionRecord on IconFeaturesSectionRecord {
  sectionTitle
  features {
    id
    title
    optText
    icon {
      responsiveImage(imgixParams: {w: 32, h: 32, auto: [compress, format]}) {
        ...DatoImage_ResponsiveImage
      }
    }
  }
  colorMode
}
    ${DatoImage_ResponsiveImageFragmentDoc}`;
export const Custom_IconLinkRecordFragmentDoc = gql`
    fragment Custom_IconLinkRecord on IconLinkRecord {
  _modelApiKey
  id
  iconName
  linkItem {
    ...Custom_CustomLinkRecord
    ...Custom_PageLinkRecord
  }
}
    ${Custom_CustomLinkRecordFragmentDoc}
${Custom_PageLinkRecordFragmentDoc}`;
export const Custom_ImageFeaturesSectionRecordFragmentDoc = gql`
    fragment Custom_ImageFeaturesSectionRecord on ImageFeaturesSectionRecord {
  sectionTitle
  features {
    id
    title
    optText
    image {
      responsiveImage(imgixParams: {w: 500, fit: max, auto: [compress, format]}) {
        ...DatoImage_ResponsiveImage
      }
    }
  }
  colorMode
}
    ${DatoImage_ResponsiveImageFragmentDoc}`;
export const Custom_ImageLinkRecordFragmentDoc = gql`
    fragment Custom_ImageLinkRecord on ImageLinkRecord {
  _modelApiKey
  id
  image {
    responsiveImage(imgixParams: {w: 1000, fit: max, auto: [compress, format]}) {
      ...DatoImage_ResponsiveImage
    }
  }
  linkItem {
    ...Custom_CustomLinkRecord
    ...Custom_PageLinkRecord
  }
}
    ${DatoImage_ResponsiveImageFragmentDoc}
${Custom_CustomLinkRecordFragmentDoc}
${Custom_PageLinkRecordFragmentDoc}`;
export const Custom_MenuLinkRecordFragmentDoc = gql`
    fragment Custom_MenuLinkRecord on MenuLinkRecord {
  _modelApiKey
  id
  linkItem {
    ...Custom_CustomLinkRecord
    ...Custom_PageLinkRecord
  }
  subLinkItems {
    ...Custom_CustomLinkRecord
    ...Custom_PageLinkRecord
    ... on MenuLinkRecord {
      _modelApiKey
      id
      linkItem {
        ...Custom_CustomLinkRecord
        ...Custom_PageLinkRecord
      }
      subLinkItems {
        ...Custom_CustomLinkRecord
        ...Custom_PageLinkRecord
      }
    }
  }
}
    ${Custom_CustomLinkRecordFragmentDoc}
${Custom_PageLinkRecordFragmentDoc}`;
export const Custom_PageHeaderRecordFragmentDoc = gql`
    fragment Custom_PageHeaderRecord on PageHeaderRecord {
  title
  optIntro
  optImage {
    responsiveImage {
      ...DatoImage_ResponsiveImage
    }
  }
  colorMode
  ctaList {
    linkItem {
      __typename
      ...Custom_CustomLinkRecord
      ...Custom_PageLinkRecord
    }
    primary
  }
}
    ${DatoImage_ResponsiveImageFragmentDoc}
${Custom_CustomLinkRecordFragmentDoc}
${Custom_PageLinkRecordFragmentDoc}`;
export const Custom_PanelLinkRecordFragmentDoc = gql`
    fragment Custom_PanelLinkRecord on PanelLinkRecord {
  _modelApiKey
  id
  mainLink {
    ...Custom_CustomLinkRecord
    ...Custom_PageLinkRecord
  }
  subLinksColumn1 {
    ...Custom_CustomLinkRecord
    ...Custom_PageLinkRecord
    ... on MenuLinkRecord {
      _modelApiKey
      id
      linkItem {
        ...Custom_CustomLinkRecord
        ...Custom_PageLinkRecord
      }
      subLinkItems {
        ...Custom_CustomLinkRecord
        ...Custom_PageLinkRecord
      }
    }
  }
  subLinksColumn2 {
    ...Custom_CustomLinkRecord
    ...Custom_PageLinkRecord
    ... on MenuLinkRecord {
      _modelApiKey
      id
      linkItem {
        ...Custom_CustomLinkRecord
        ...Custom_PageLinkRecord
      }
      subLinkItems {
        ...Custom_CustomLinkRecord
        ...Custom_PageLinkRecord
      }
    }
  }
  subLinksColumn3 {
    ...Custom_CustomLinkRecord
    ...Custom_PageLinkRecord
    ... on MenuLinkRecord {
      _modelApiKey
      id
      linkItem {
        ...Custom_CustomLinkRecord
        ...Custom_PageLinkRecord
      }
      subLinkItems {
        ...Custom_CustomLinkRecord
        ...Custom_PageLinkRecord
      }
    }
  }
  callToAction {
    ...Custom_CustomLinkRecord
    ...Custom_PageLinkRecord
  }
  sidePanel {
    title
    image {
      responsiveImage(imgixParams: {w: "310", auto: [compress]}) {
        ...DatoImage_ResponsiveImage
      }
    }
    link {
      ...Custom_CustomLinkRecord
      ...Custom_PageLinkRecord
    }
  }
}
    ${Custom_CustomLinkRecordFragmentDoc}
${Custom_PageLinkRecordFragmentDoc}
${DatoImage_ResponsiveImageFragmentDoc}`;
export const Custom_SectionDividerRecordFragmentDoc = gql`
    fragment Custom_SectionDividerRecord on SectionDividerRecord {
  id
}
    `;
export const Custom_TeaserRecordFragmentDoc = gql`
    fragment Custom_TeaserRecord on TeaserRecord {
  id
  title
  optText
  image {
    responsiveImage {
      ...DatoImage_ResponsiveImage
    }
  }
  link {
    ...Custom_CustomLinkRecord
    ...Custom_PageLinkRecord
  }
}
    ${DatoImage_ResponsiveImageFragmentDoc}
${Custom_CustomLinkRecordFragmentDoc}
${Custom_PageLinkRecordFragmentDoc}`;
export const Custom_TeaserCarouselSectionRecordFragmentDoc = gql`
    fragment Custom_TeaserCarouselSectionRecord on TeaserCarouselSectionRecord {
  id
  sectionTitle
  teasers {
    ...Custom_TeaserRecord
  }
  slidesPerView
  variant
}
    ${Custom_TeaserRecordFragmentDoc}`;
export const Custom_TechnicalDetailRecordFragmentDoc = gql`
    fragment Custom_TechnicalDetailRecord on TechnicalDetailRecord {
  _modelApiKey
  id
  title
  info
}
    `;
export const Custom_TechnicalDetailsSectionRecordFragmentDoc = gql`
    fragment Custom_TechnicalDetailsSectionRecord on TechnicalDetailsSectionRecord {
  id
  sectionTitle
  title
  technicalDetails {
    ...Custom_TechnicalDetailRecord
  }
  image {
    responsiveImage(imgixParams: {w: "1500", auto: [compress]}) {
      ...DatoImage_ResponsiveImage
    }
  }
  downloadLink {
    ...Custom_CustomLinkRecord
    ...Custom_PageLinkRecord
  }
}
    ${Custom_TechnicalDetailRecordFragmentDoc}
${DatoImage_ResponsiveImageFragmentDoc}
${Custom_CustomLinkRecordFragmentDoc}
${Custom_PageLinkRecordFragmentDoc}`;
export const Custom_TextAndImageSectionRecordFragmentDoc = gql`
    fragment Custom_TextAndImageSectionRecord on TextAndImageSectionRecord {
  id
  sectionTitle
  titleAndText {
    title
    text
    link {
      ...Custom_CustomLinkRecord
      ...Custom_PageLinkRecord
    }
    linkVariant
  }
  image {
    responsiveImage(imgixParams: {w: 750, fit: max, auto: [compress, format]}) {
      ...DatoImage_ResponsiveImage
    }
  }
  imageSide
}
    ${Custom_CustomLinkRecordFragmentDoc}
${Custom_PageLinkRecordFragmentDoc}
${DatoImage_ResponsiveImageFragmentDoc}`;
export const Custom_TitleAndTextSectionRecordFragmentDoc = gql`
    fragment Custom_TitleAndTextSectionRecord on TitleAndTextSectionRecord {
  id
  sectionTitle
  titleAndText {
    title
    text
    link {
      ...Custom_CustomLinkRecord
      ...Custom_PageLinkRecord
    }
    linkVariant
  }
  colorMode
  extraMargin
}
    ${Custom_CustomLinkRecordFragmentDoc}
${Custom_PageLinkRecordFragmentDoc}`;
export const Custom_UspRecordFragmentDoc = gql`
    fragment Custom_UspRecord on UspRecord {
  _modelApiKey
  id
  title
  text
  image {
    responsiveImage(imgixParams: {w: 1000, fit: max, auto: [compress, format]}) {
      ...DatoImage_ResponsiveImage
    }
  }
}
    ${DatoImage_ResponsiveImageFragmentDoc}`;
export const Custom_UspListSectionRecordFragmentDoc = gql`
    fragment Custom_UspListSectionRecord on UspListSectionRecord {
  id
  sectionTitle
  sellingPoints {
    ...Custom_UspRecord
  }
  colorMode
}
    ${Custom_UspRecordFragmentDoc}`;
export const Custom_UspScrollSectionRecordFragmentDoc = gql`
    fragment Custom_UspScrollSectionRecord on UspScrollSectionRecord {
  id
  sectionTitle
  title
  sellingPoints {
    ...Custom_UspRecord
  }
  callToActionText
  callToActionLink {
    ...Custom_CustomLinkRecord
    ...Custom_PageLinkRecord
  }
}
    ${Custom_UspRecordFragmentDoc}
${Custom_CustomLinkRecordFragmentDoc}
${Custom_PageLinkRecordFragmentDoc}`;
export const CategoriesDetailDocument = gql`
    query CategoriesDetail($slug: String, $locale: SiteLocale) {
  categoryPage(locale: $locale, filter: {slug: {eq: $slug}}) {
    id
    code
    title
    slug
    sections {
      __typename
      ...Custom_ContentSectionRecord
    }
    _seoMetaTags {
      content
      tag
      attributes
    }
    _allSlugLocales {
      locale
      value
    }
  }
}
    ${Custom_ContentSectionRecordFragmentDoc}`;
export const Error404Document = gql`
    query Error404($locale: SiteLocale) {
  error404(locale: $locale) {
    id
    body {
      blocks {
        ...Custom_ImageBlockRecord
      }
      links
      value
    }
    _seoMetaTags {
      content
      tag
      attributes
    }
  }
}
    ${Custom_ImageBlockRecordFragmentDoc}`;
export const ContactFormDocument = gql`
    query ContactForm($locale: SiteLocale) {
  contactForm(locale: $locale) {
    id
    titleDealer
    titleInfo
    titleReason
    labelCompanyName
    labelCompanyNumber
    labelDealerName
    labelEMailAddress
    labelFirstName
    labelLastName
    labelMessage
    labelPhoneCountryCode
    labelPhoneNumber
    labelSubject
    placeholderSubject
    helpTextMessage
    actionSubmit
    countries {
      id
      name
      isoCode
      phoneNumberCode
    }
    dealers {
      id
      name
      address
      phoneNumber
      eMailAddress
    }
    subjects {
      text
    }
    confirmation {
      optTitle
      text
    }
    exception {
      optTitle
      text
    }
  }
}
    `;
export const LayoutDocument = gql`
    query Layout($locale: SiteLocale) {
  layout(locale: $locale) {
    menu {
      ...Custom_PanelLinkRecord
      ...Custom_PageLinkRecord
    }
    taglineHtml
    footerLinkColumns {
      _modelApiKey
      id
      linkSet {
        _modelApiKey
        id
        setTitle
        linkItems {
          __typename
          ...Custom_CustomLinkRecord
          ...Custom_PageLinkRecord
        }
      }
    }
    newsletterSubscription {
      id
      newsletter {
        id
        title
        optText
        inputPlaceholder
        buttonText
        remoteFormUrl
        confirmation {
          optTitle
          text
        }
        exception {
          optTitle
          text
        }
      }
    }
    footerSubtitle
    footerLegalLinks {
      title
      slug
    }
    socialMedia {
      platform
      url
    }
  }
}
    ${Custom_PanelLinkRecordFragmentDoc}
${Custom_PageLinkRecordFragmentDoc}
${Custom_CustomLinkRecordFragmentDoc}`;
export const LegalDocument = gql`
    query Legal($slug: String, $locale: SiteLocale) {
  legalPage(locale: $locale, filter: {slug: {eq: $slug}}) {
    id
    title
    slug
    content {
      blocks
      links
      value
    }
    _seoMetaTags(locale: $locale) {
      attributes
      content
      tag
    }
    _allSlugLocales {
      locale
      value
    }
  }
}
    `;
export const PageDocument = gql`
    query Page($slug: String, $locale: SiteLocale) {
  page(filter: {slug: {eq: $slug}}, locale: $locale) {
    id
    header {
      ...Custom_PageHeaderRecord
    }
    sections {
      __typename
      ...Custom_BannerCarouselSectionRecord
      ...Custom_CallToActionSectionRecord
      ...Custom_CenteredTextSectionRecord
      ...Custom_ContactTeasersSectionRecord
      ...Custom_ContentSectionRecord
      ...Custom_DealersSectionRecord
      ...Custom_IconFeaturesSectionRecord
      ...Custom_ImageFeaturesSectionRecord
      ...Custom_SectionDividerRecord
      ...Custom_TeaserCarouselSectionRecord
      ...Custom_TechnicalDetailsSectionRecord
      ...Custom_TextAndImageSectionRecord
      ...Custom_TitleAndTextSectionRecord
      ...Custom_UspScrollSectionRecord
      ...Custom_UspListSectionRecord
    }
    _seoMetaTags {
      content
      tag
      attributes
    }
    _allSlugLocales {
      locale
      value
    }
  }
}
    ${Custom_PageHeaderRecordFragmentDoc}
${Custom_BannerCarouselSectionRecordFragmentDoc}
${Custom_CallToActionSectionRecordFragmentDoc}
${Custom_CenteredTextSectionRecordFragmentDoc}
${Custom_ContactTeasersSectionRecordFragmentDoc}
${Custom_ContentSectionRecordFragmentDoc}
${Custom_DealersSectionRecordFragmentDoc}
${Custom_IconFeaturesSectionRecordFragmentDoc}
${Custom_ImageFeaturesSectionRecordFragmentDoc}
${Custom_SectionDividerRecordFragmentDoc}
${Custom_TeaserCarouselSectionRecordFragmentDoc}
${Custom_TechnicalDetailsSectionRecordFragmentDoc}
${Custom_TextAndImageSectionRecordFragmentDoc}
${Custom_TitleAndTextSectionRecordFragmentDoc}
${Custom_UspScrollSectionRecordFragmentDoc}
${Custom_UspListSectionRecordFragmentDoc}`;
export const SeriesDetailDocument = gql`
    query SeriesDetail($slug: String, $locale: SiteLocale) {
  seriesPage(locale: $locale, filter: {slug: {eq: $slug}}) {
    id
    code
    title
    slug
    sections {
      __typename
    }
    _seoMetaTags {
      content
      tag
      attributes
    }
    _allSlugLocales {
      locale
      value
    }
  }
}
    `;
export const SolutionsDetailDocument = gql`
    query SolutionsDetail($slug: String, $locale: SiteLocale) {
  solutionPage(locale: $locale, filter: {slug: {eq: $slug}}) {
    id
    code
    title
    slug
    sections {
      __typename
      ...Custom_ContentSectionRecord
    }
    _seoMetaTags {
      content
      tag
      attributes
    }
    _allSlugLocales {
      locale
      value
    }
  }
}
    ${Custom_ContentSectionRecordFragmentDoc}`;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string, variables?: any) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();
const CategoriesDetailDocumentString = print(CategoriesDetailDocument);
const Error404DocumentString = print(Error404Document);
const ContactFormDocumentString = print(ContactFormDocument);
const LayoutDocumentString = print(LayoutDocument);
const LegalDocumentString = print(LegalDocument);
const PageDocumentString = print(PageDocument);
const SeriesDetailDocumentString = print(SeriesDetailDocument);
const SolutionsDetailDocumentString = print(SolutionsDetailDocument);
export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    CategoriesDetail(variables?: CategoriesDetailQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: CategoriesDetailQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CategoriesDetailQuery>(CategoriesDetailDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CategoriesDetail', 'query', variables);
    },
    Error404(variables?: Error404QueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: Error404Query; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<Error404Query>(Error404DocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Error404', 'query', variables);
    },
    ContactForm(variables?: ContactFormQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: ContactFormQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<ContactFormQuery>(ContactFormDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ContactForm', 'query', variables);
    },
    Layout(variables?: LayoutQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: LayoutQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<LayoutQuery>(LayoutDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Layout', 'query', variables);
    },
    Legal(variables?: LegalQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: LegalQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<LegalQuery>(LegalDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Legal', 'query', variables);
    },
    Page(variables?: PageQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: PageQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<PageQuery>(PageDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Page', 'query', variables);
    },
    SeriesDetail(variables?: SeriesDetailQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: SeriesDetailQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<SeriesDetailQuery>(SeriesDetailDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SeriesDetail', 'query', variables);
    },
    SolutionsDetail(variables?: SolutionsDetailQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: SolutionsDetailQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<SolutionsDetailQuery>(SolutionsDetailDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SolutionsDetail', 'query', variables);
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;