import type { PublicStorefrontConfig } from "~~/server/models/store";

export async function useStore() {
  const config = useState("store-config", () => ref<PublicStorefrontConfig | null>(null));

  await callOnce(async () => {
    // runs on server if ssr
    const headers = useRequestHeaders(["cookie"]);
    config.value = await $fetch<PublicStorefrontConfig>("/api/store", { headers: headers });
  });

  return config;
}
