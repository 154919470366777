import { SiteLocale } from "~~/server/graphql/datocms/generated";

const datoEnumToCulture: Map<SiteLocale, string> = new Map([
  [SiteLocale.Nl, "nl"],
]);

const cultureToDatoEnum: Map<string, SiteLocale> = new Map([
  ["nl", SiteLocale.Nl],
]);

export function getCultureFromDatoLocale(datoLocale: SiteLocale): string {
  return datoEnumToCulture.get(datoLocale) as string;
}

export function getDatoLocaleFromCulture(culture: string): SiteLocale {
  return cultureToDatoEnum.get(culture.toLocaleLowerCase()) as SiteLocale;
}
