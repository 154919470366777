<script lang="ts" setup>
import type { Error404Record } from "~~/server/graphql/datocms/generated";
import { toHead, type ToMetaTagsType } from "vue-datocms";

defineProps({
  error: Object,
});

const { data } = await useFetch<Error404Record>("/api/cms/error/404");

const showCustomPage = computed(() => {
  return data.value;
});

if (data.value) {
  useHead(() => {
    if (!data.value) {
      return {};
    }

    return toHead(data.value._seoMetaTags as ToMetaTagsType);
  });
}
</script>

<template>
  <NuxtLayout>
    <StructuredContent v-if="showCustomPage" :data="data?.body" />
    <ErrorAll v-if="!showCustomPage" :error="error" />
  </NuxtLayout>
</template>

<style scoped>

</style>
