<script setup lang="ts">
// https://nuxt.com/docs/getting-started/error-handling#error-page
import type { NuxtError } from "#app";

const props = defineProps({
  error: Object as () => NuxtError,
});

useHead({
  // TODO: use storefront name
  title: `Hangcha - Error ${props.error?.statusCode}`,
});
</script>

<template>
  <Head>
    <Meta name="robots" content="noindex" />
  </Head>

  <Error404 v-if="error?.statusCode === 404" :error="error" />

  <main
    v-if="error?.statusCode !== 404"
    class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8"
  >
    <ErrorAll :error="error" />
  </main>
</template>
