<script setup lang="ts">
// https://scripts.nuxt.com/scripts/tracking/google-tag-manager
// Call GTM via useGtmTracking in other places.
const store = await useStore();
useScriptGoogleTagManager({
  id: store.value?.gtmId || "",
});
</script>

<template>
  <NuxtLoadingIndicator color="#cc9f26" />
  <NuxtRouteAnnouncer />

  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>

  <UModals />

  <UNotifications>
    <template #description="{ description }">
      <span v-html="description" />
    </template>
  </UNotifications>
</template>
